import React from 'react';
import headElipse from '../../../../../assets/img/portfolio-case-img/BlockchainPayment/headElipse.png';
import headAndFooterImage from '../../../../../assets/img/portfolio-case-img/BlockchainPayment/headAndFooterImage.png';
import rocket from '../../../../../assets/img/portfolio-case-img/rocket.png';
import descriptionImage from '../../../../../assets/img/portfolio-case-img/BlockchainPayment/descriptionImage.png';
import problemsElipse from '../../../../../assets/img/portfolio-case-img/BlockchainPayment/problemsElipse.png';
import problemsImage from '../../../../../assets/img/portfolio-case-img/BlockchainPayment/problemsImage.png';
import detailsImage from '../../../../../assets/img/portfolio-case-img/BlockchainPayment/detailsImage.png';
import { CaseTitle } from '../../components/CaseTitle';
import { CaseArticle } from '../../components/CaseArticle';
import './BlockchainPaymentCase.scss';
import { CaseReview } from '../../components/CaseReview';
import reviewBackground from '../../../../../assets/img/portfolio-case-img/BlockchainPayment/reviewElipse.png';
import { CaseFooter } from '../../components/CaseFooter';

export const BlockchainPaymentCase = () => {
	return (
		<div className="caseContainer">
			<img className="headImage" src={headElipse} alt="" />
			<div className="caseHeader">
				<CaseTitle title="Instant low-cost retail payments" />
			</div>
			<div className="caseBody blockchainPayment__body">
				<div className="blockchainPayment__caseAbout">
					<CaseArticle
						classes="blockchainPayment__caseAbout__article"
						textClasses="blockchainPayment__caseAbout__article-text"
						withBackground
						articleText="This particular case shows a licensed digital asset that facilitates regulated and secure money transfer, a low-cost, ultra-fast, and ultra-secure blockchain rail for consumers and businesses to pay merchants. "
					/>
					<div className="blockchainPayment__caseAbout__image-wrapper">
						<img src={rocket} className="blockchainPayment__caseAbout__rocket" alt="" />
						<img src={headAndFooterImage} className="blockchainPayment__caseAbout__image" alt="" />
					</div>
				</div>
				<div className="blockchainPayment__description">
					<img src={descriptionImage} className="image blockchainPayment__description__image" alt="" />

					<CaseArticle
						withBackground
						classes="blockchainPayment__description__article"
						articleTitle="Description"
						articleText="Client was looking for ultra-secure and flexible payment choices for consumers and businesses. This powered by a patented, ultra-secure, point-to-point blockchain payment routing network. Your payments can be linked to any of your checking, savings, loans, or even credit accounts. "
					/>
				</div>
				<div className="blockchainPayment__problems caseProblems">
					<img src={problemsElipse} className="caseProblems__background" alt="" />
					<CaseArticle
						classes="caseProblems__article"
						titleClasses="caseProblems__article-title"
						articleTitle="Problem and Solution"
						articleText="Blockchain technology produces a structure of data with inherent security qualities. It's based on principles of cryptography, decentralization and consensus, which ensure trust in transactions. In most blockchains or distributed ledger technologies (DLT), the data is structured into blocks and each block contains a transaction or bundle of transactions. Each new block connects to all the blocks before it in a cryptographic chain in such a way that it's nearly impossible to tamper with. All transactions within the blocks are validated and agreed upon by a consensus mechanism, ensuring that each transaction is true and correct.
"
					/>
					<div className="blockchainPayment__problems__image-wrapper">
						<img src={problemsImage} className="image" alt="" />
					</div>
				</div>
				<div className="blockchainPayment__details">
					<CaseArticle
						classes="blockchainPayment__details__article"
						withBackground
						articleTitle="Details"
						articleText="Blockchain technology enables decentralization through the participation of members across a distributed network. BlockchainPay provides patented, innovative, adaptive, and scalable blockchain payment technologies and solutions."
					/>
					<div className="blockchainPayment__details__image-wrapper">
						<img src={detailsImage} className="image" alt="" />
					</div>
				</div>
				<div className="blockchainPayment__review">
					<img src={reviewBackground} className="blockchainPayment__review__background" alt="" />
					<CaseReview
						name="Peter Buss"
						position="CFO"
						text="Alpha Solutions has become an important partner for us. Thanks to them, our site is developed using the latest technologies that allow the user to quickly find and get help from us. We will definitely cooperate in the future, as it is this company that embodies all our requirements."
					/>
				</div>
				<CaseFooter image={headAndFooterImage} />
			</div>
		</div>
	);
};
