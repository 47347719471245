import React, { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import CaptionH2 from '../../ui/caption/CaptionH2';
import mvpImage from '../../assets/img/mvp/mvpImage.png';
import vector from '../../assets/img/mvp/vector.svg';
import vectorMob from '../../assets/img/mvp/vectorMob.svg';
import servicesElipse from '../../assets/img/mvp/mvpElipse.png';
import './MVPPage.scss';
import { MVPCard } from './components/MVPCard';
import { areas } from './consts';
import Button from '../../ui/button/button';

const MVPPage = () => {
	const history = useHistory();
	useEffect(() => {
		document.title = 'MVP';
	});
	return (
		<>
			<div className="mvpContainer">
				<CaptionH2 title="MVP" styles="first" />
				<div className="mvpContainer__whatIs">
					<div className="container">
						<div className="mvpContainer__whatIs__inner">
							<div className="mvpContainer__whatIs__inner__imageWrapper">
								<img src={mvpImage} className="image" alt="mvp" />
							</div>
							<div className="mvpContainer__whatIs__inner__description">
								<h3 className="mvpContainer__whatIs__inner__description__title">What is an Mvp</h3>
								<p className="mvpContainer__text">
									A minimum viable product (MVP) is a product that has just enough features to satisfy early customers
									and fulfill at least one of their identified needs. It is called “minimum” because of its minimalistic
									nature with regard to features and investment, and “viable” because it has to work in real life. The
									MVP is launched to link target customers to a product’s early version and provide their feedback for
									future development.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="mvpContainer__services">
					<img src={servicesElipse} className="mvpContainer__services__elipse" alt="" />
					<div className="container">
						<div className="mvpContainer__services__inner">
							<div className="mvpContainer__services__inner__description">
								<h3 className="mvpContainer__services__inner__description__title">What services you get</h3>
								<p className="mvpContainer__text mvpContainer__services__inner__description__text">
									The advantages of an MVP are related to fewer costs, lower risks and customer insights. It is the most
									pared down version of a product that can still be released and satisfy a customer’s need.{' '}
								</p>
							</div>
							<div className="mvpContainer__services__inner__content">
								<ReactSVG src={vector} className="mvpContainer__services__inner__content__vector" />
								<ReactSVG src={vectorMob} className="mvpContainer__services__inner__content__vector_mob" />
								<div className="mvpContainer__services__inner__content__item mvpContainer__services__inner__content__item_business">
									Business analytics
								</div>
								<div className="mvpContainer__services__inner__content__item mvpContainer__services__inner__content__item_design">
									Design
								</div>
								<div className="mvpContainer__services__inner__content__item mvpContainer__services__inner__content__item_development">
									Development
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="mvpContainer__areas">
					<div className="container">
						<h3 className="mvpContainer__areas__title">MVP areas we work with</h3>
						<div className="mvpContainer__areas__row mvpContainer__areas__row__first">
							{areas.firstRow.map(item => (
								<MVPCard
									title={item.title}
									imgSrc={item.imgSrc}
									alt={item.alt}
									cardImgClasses={item.cardImgClasses}
									rootImgClasses=""
									key={item.id}
									classes="mvpContainer__areas__row__first__item"
								/>
							))}
						</div>
						<div className="mvpContainer__areas__row mvpContainer__areas__row__second">
							{areas.secondRow.map(item => (
								<MVPCard
									title={item.title}
									imgSrc={item.imgSrc}
									alt={item.alt}
									cardImgClasses={item.cardImgClasses}
									rootImgClasses="mvpContainer__areas__row__second__item__image"
									key={item.id}
									classes="mvpContainer__areas__row__second__item"
								/>
							))}
						</div>
						<div className="mvpContainer__areas__row mvpContainer__areas__row__third">
							{areas.thirdRow.map(item => (
								<MVPCard
									title={item.title}
									imgSrc={item.imgSrc}
									alt={item.alt}
									cardImgClasses={item.cardImgClasses}
									rootImgClasses="mvpContainer__areas__row__third__item__image"
									key={item.id}
									classes="mvpContainer__areas__row__third__item"
								/>
							))}
						</div>
						<Button
							title="More"
							onClick={() => history.push('/contact-us')}
							classes="mvpContainer__areas__button"
							mainClass="primary"
							secClass="big"
						/>
					</div>
				</div>
			</div>
		</>
	);
};
export default MVPPage;
