// import { HomePage } from './pages/HomePage/HomePage';
import { BlogPage } from './pages/BlogPage/BlogPage';
// import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage';
import { TechnologiesPage } from './pages/TechnologiesPage/TechnologiesPage';
import { PortfolioPage } from './pages/PortfolioPage/PortfolioPage';
import { PackagesOfCooperationPage } from './pages/Packages-Of-CooperationPage/Packages-Of-CooperationPage';
import { ContactUsPage } from './pages/Contact-UsPage/Contact-UsPage';
import MVPPage from './pages/MVPPage/MVPPage';
// import { Article } from './pages/BlogPage/Article/Article';

export const links = [
	{
		title: 'Technologies',
		route: '/technologies',
		id: 1,
		component: TechnologiesPage,
	},
	{
		title: 'Portfolio',
		route: '/portfolio',
		id: 2,
		component: PortfolioPage,
	},
	{
		title: 'Packages of cooperation',
		route: '/packages-of-cooperation',
		id: 3,
		component: PackagesOfCooperationPage,
	},
	{
		title: 'MVP',
		route: '/mvp',
		id: 4,
		component: MVPPage,
	},
	{
		title: 'Blog',
		route: '/blog',
		id: 5,
		component: BlogPage,
	},
	{
		title: 'Contact us',
		route: '/contact-us',
		id: 6,
		component: ContactUsPage,
	},
];
