import React from 'react';
import headElipse from '../../../../../assets/img/portfolio-case-img/erpSystem/headElipse.png';
import dashBoardImac from '../../../../../assets/img/portfolio-case-img/erpSystem/dashboardImac.png';
import vectorArrow from '../../../../../assets/img/portfolio-case-img/erpSystem/vectorArrow.png';
import descriptionImage from '../../../../../assets/img/portfolio-case-img/erpSystem/descriptionImage.png';
import twoImac from '../../../../../assets/img/portfolio-case-img/erpSystem/twoImac.png';
import problemsElipse from '../../../../../assets/img/portfolio-case-img/erpSystem/problemsElipse.png';
import editFormScreen from '../../../../../assets/img/portfolio-case-img/erpSystem/editFormScreen.png';
import congratulationsScreen from '../../../../../assets/img/portfolio-case-img/erpSystem/congratulationScreen.png';
import calendarScreen from '../../../../../assets/img/portfolio-case-img/erpSystem/calendarScreen.png';
import notificationsScreen from '../../../../../assets/img/portfolio-case-img/erpSystem/notificationsScreen.png';
import passwordRemindScreen from '../../../../../assets/img/portfolio-case-img/erpSystem/passwordrRemaindScreen.png';
import vector from '../../../../../assets/img/portfolio-case-img/erpSystem/vector.png';
import vectorMob from '../../../../../assets/img/portfolio-case-img/erpSystem/vectorMob.png';
import reviewElipse from '../../../../../assets/img/portfolio-case-img/erpSystem/reviewElipse.png';
import '../../Case.scss';
import './ErpSystemCase.scss';
import { CaseTitle } from '../../components/CaseTitle';
import { CaseArticle } from '../../components/CaseArticle';
import { CaseReview } from '../../components/CaseReview';
import { CaseFooter } from '../../components/CaseFooter';

export const ErpSystemCase = () => {
	return (
		<>
			<div className="caseContainer">
				<CaseTitle title="ERP System" />
				<img src={headElipse} className="headImage erpSystem__headImage" alt="" />
				<div className="caseBody erpSystem__caseBody">
					<div className="erpSystem__caseAbout">
						<img src={vectorArrow} className="erpSystem__caseAbout__vectorArrow" alt="" />
						<CaseArticle
							withBackground
							classes="erpSystem__caseAbout__article"
							articleText="
ERP ( enterprise resource planning ) is designed to manage team relationships with the overall goal to increase sales volume. ERP is used to manage internal business processes rather than customers. The main goal with ERP software is to reduce overhead and costs by making business operations more efficient. ERP stands for Enterprise Resource Planning; it standardizes and streamline business processes across services, sales, finance etc.
"
						/>
						<div className="erpSystem__caseAbout__iMacWrapper">
							<img src={dashBoardImac} className="image" alt="" />
						</div>
					</div>
					<div className="erpSystem__description">
						<div className="erpSystem__description__imageWrapper">
							<img src={descriptionImage} className="image" alt="" />
						</div>
						<CaseArticle
							withBackground
							classes="erpSystem__description__article"
							articleTitle="Description"
							articleText="Today, these phrases are used less frequently as the important factor is not company size but determining if the ERP system is effectively addressing current and future business requirements, no matter the size of the organization.It’s imperative that organizations consider and select ERP systems that eliminate the need for costly customizations, adapt to the rapid pace of business change, address future technologies and meet other identified requirements.
"
						/>
					</div>
					<div className="erpSystem__problems">
						<img src={problemsElipse} className="erpSystem__problems__elipse" alt="" />
						<CaseArticle
							withBackground
							classes="erpSystem__problems__article"
							articleTitle="Problem  and Solution"
							articleText="ERP software can integrate all of the processes needed to run a company.
ERP solutions have evolved over the years, and many are now typically web-based applications that users can access remotely.
Some benefits of ERP include the free flow of communication between business areas, a single source of information, and accurate, real-time data reporting.
"
						/>
						<div className="erpSystem__problems__twoImacWrapper">
							<img src={twoImac} className="image" alt="" />
						</div>
					</div>
					<div className="erpSystem__images">
						<img src={vector} className="erpSystem__images__vector" alt="" />
						<img src={vectorMob} className="erpSystem__images__vector_mob" alt="" />
						<img src={editFormScreen} className="erpSystem__images__image erpSystem__images__editFormScreen" alt="" />
						<img
							src={congratulationsScreen}
							className="erpSystem__images__image erpSystem__images__congratulationsScreen"
							alt=""
						/>
						<img src={calendarScreen} className="erpSystem__images__image erpSystem__images__calendarScreen" alt="" />
						<img
							src={notificationsScreen}
							className="erpSystem__images__image erpSystem__images__notificationsScreen"
							alt=""
						/>
						<img
							src={passwordRemindScreen}
							className="erpSystem__images__image erpSystem__images__passwordRemindScreen"
							alt=""
						/>
					</div>
					<div className="erpSystem__review">
						<img src={reviewElipse} className="erpSystem__review__elipse" alt="" />
						<CaseReview
							name="Dylan Loyers"
							position="CEO"
							text="“Cooperation with Alpha Solutions has made a great contribution to the development of our company. They helped us in creating a really important technology like an ERP system. This has significantly improved our workflow, helped to conveniently and quickly keep track of all important work data.”"
						/>
					</div>
					<CaseFooter classes="erpSystem__footer" imageClasses="erpSystem__footer__image" image={twoImac} />
				</div>
			</div>
		</>
	);
};
