import React from 'react';

import headElipse from '../../../../../assets/img/portfolio-case-img/finAggregator/headElipse1.png';
import caseAboutPhones from '../../../../../assets/img/portfolio-case-img/finAggregator/caseAboutPhones.png';
import iMac from '../../../../../assets/img/portfolio-case-img/finAggregator/caseAboutImac.png';
import descriptionImage from '../../../../../assets/img/portfolio-case-img/finAggregator/descriptionImage.png';
import iPad from '../../../../../assets/img/portfolio-case-img/finAggregator/iPadImage.png';
import problemsMainPage from '../../../../../assets/img/portfolio-case-img/finAggregator/problemsMainPage.png';
import problemsDashboard from '../../../../../assets/img/portfolio-case-img/finAggregator/problemsDashboard.png';
import problemsElipse from '../../../../../assets/img/portfolio-case-img/finAggregator/problemsElipse.png';
import reviewElipse from '../../../../../assets/img/portfolio-case-img/finAggregator/reviewElipse.png';
import '../../Case.scss';
import './FinAggregator.scss';
import { CaseTitle } from '../../components/CaseTitle';
import { CaseArticle } from '../../components/CaseArticle';
import { CaseReview } from '../../components/CaseReview';
import { CaseFooter } from '../../components/CaseFooter';

export const FinAggregator = () => {
	return (
		<>
			<div className="caseContainer">
				<img src={headElipse} className="finAggregator__headImage" alt="" />
				<CaseTitle title="Financial aggregator" />
				<div className="caseBody finAggregator__caseBody">
					<div className="finAggregator__caseAbout">
						<div className="finAggregator__caseAbout__description">
							<CaseArticle
								classes="finAggregator__caseAbout__article"
								withBackground
								articleText="Lets look deeper into definition of account aggregation: Account aggregation is a process in which data from many-or all-of an individual's or household’s financialaccounts are collected in one place. It is also referred to as financial data aggregation. For example, anonline banking service may provide a home page on which account holders can see information from all of theirchecking, savings, CDs, and brokerage accounts. Personal finance software, apps, and online services likeQuicken or Mint also provide account aggregation services."
							/>
							<div className="finAggregator__caseAbout__phonesWrapper">
								<img src={caseAboutPhones} className="image" alt="" />
							</div>
						</div>
						<div className="finAggregator__caseAbout__iMacWrapper">
							<img src={iMac} className="image" alt="" />
						</div>
					</div>
					<div className="finAggregator__description">
						<div className="finAggregator__description__content">
							<div className="finAggregator__description__imageWrapper">
								<img src={descriptionImage} className="image" alt="" />
							</div>
							<CaseArticle
								classes="finAggregator__description__article"
								withBackground
								articleTitle="Description"
								articleText="The main purpose of this app was to give a flexible functionality that could allow users to choose asuitable bank and perform various operations such as: Open a deposit Take a loan"
							/>
						</div>
						<div className="finAggregator__description__iPadWrapper">
							<img src={iPad} className="image" alt="" />
						</div>
					</div>
					<div className="finAggregator__problems">
						<img src={problemsElipse} className="finAggregator__problems__elipse" alt="" />
						<div className="finAggregator__problems__content">
							<CaseArticle
								withBackground
								classes="finAggregator__problems__article"
								titleClasses="finAggregator__problems__article-title"
								articleTitle="Problem and Solution"
								articleText="Benefits of Account Aggregation: Account aggregation can be a useful financial management and planning tool, providing streamlined accountaccess for account holders. Aggregating accounts can be particularly beneficial for families who have multiplefinancial goals, such as saving for retirement and college, because the statements give a more complete picture of the family's financial assets. In one form of account aggregation, called householding, all of the savings, checking, brokerage, and otheraccounts belonging to the members of a particular household are linked. Married couples and domestic partnersmay use a householded acccount to manage their shared finances and work toward their shared financial goals."
							/>
							<div className="finAggregator__problems__mainPageWrapper">
								<img src={problemsMainPage} className="image" alt="" />
							</div>
						</div>
						<div className="finAggregator__problems__dashboardWrapper">
							<img src={problemsDashboard} className="image" alt="" />
						</div>
					</div>
					<div className="finAggregator__caseReview">
						<img src={reviewElipse} className="finAggregator__caseReview__elipse" alt="" />
						<CaseReview
							name="Larry Store"
							position="CEO"
							text="“The work of Alpha Solutions was carried out clearly and on time. We were completely satisfied with the result and would definitely recommend working with them. The main qualities of this company are professionalism, diligence and a special approach to each client.”"
						/>
					</div>
					<CaseFooter image={iMac} classes="finAggregator__footer" />
				</div>
			</div>
		</>
	);
};
