import React from 'react';
import '../../Case.scss';
import './ECommerceCase.scss';
import headElipse from '../../../../../assets/img/portfolio-case-img/ECommerce/headElipse.png';
import headAndFooterImage from '../../../../../assets/img/portfolio-case-img/ECommerce/headAndFooterImage.png';
import arrow from '../../../../../assets/img/portfolio-case-img/ECommerce/arrow.png';
import arrowMob from '../../../../../assets/img/portfolio-case-img/ECommerce/arrowMob.png';
import descriptionImage from '../../../../../assets/img/portfolio-case-img/ECommerce/descriptionImage.png';
import problemsElipse from '../../../../../assets/img/portfolio-case-img/ECommerce/problemsElipse.png';
import problemsIpad from '../../../../../assets/img/portfolio-case-img/ECommerce/problemsIpad.png';
import aboutUsImage from '../../../../../assets/img/portfolio-case-img/ECommerce/aboutUsImage.png';
import formImage from '../../../../../assets/img/portfolio-case-img/ECommerce/formImage.png';
import rocket from '../../../../../assets/img/portfolio-case-img/rocket.png';
import reviewBackground from '../../../../../assets/img/portfolio-case-img/ECommerce/reviewBackground.png';
import { CaseTitle } from '../../components/CaseTitle';
import { CaseArticle } from '../../components/CaseArticle';
import { CaseReview } from '../../components/CaseReview';
import { CaseFooter } from '../../components/CaseFooter';

export const ECommerce = () => {
	return (
		<>
			<div className="caseContainer">
				<img className="headImage eCommerce__headImage" src={headElipse} alt="" />
				<div className="caseHeader">
					<CaseTitle title="E-commerce solution" />
				</div>
				<div className="caseBody eCommerce__body">
					<div className="eCommerce__caseAbout">
						<CaseArticle
							withBackground
							classes="eCommerce__caseAbout__article"
							textClasses="eCommerce__caseAbout__article-text"
							articleText="The main functionality in this case is ordering food in respectable amounts from a dozen of companies that arelisted on the website, with a simple ordering process and shipment tracking."
						/>
						<div className="eCommerce__caseAbout__imageWrapper">
							<img src={headAndFooterImage} className="image" alt="" />
							<img src={arrow} className="eCommerce__caseAbout__arrow" alt="" />
							<img src={arrowMob} className="eCommerce__caseAbout__arrow-mob" alt="" />
						</div>
					</div>
					<div className="eCommerce__description">
						<div className="eCommerce__description__imageWrapper">
							<img src={descriptionImage} className="image" alt="" />
						</div>
						<CaseArticle
							withBackground
							classes="eCommerce__description__article"
							articleTitle="Description"
							articleText="
Client idea was to create an E-commerce website focused on wholesale food and groceries. Wholesale ecommerce refers to selling products to another business, in bulk and at a discount, online.Wholesalers make money by selling their products from their ecommerce site or storefront, usually to end consumers, at a higher price. Technology like B2B ecommerce platforms have made it easier for wholesalers to sell online and to take advantage of the following benefits: It simplifies the buying process It helps automates wholesale processes It improves the buyer experience It increases sales while lowering spend It helps enter new markets"
						/>
					</div>
					<div className="eCommerce__problems">
						<img src={problemsElipse} className="eCommerce__problems__background" alt="" />
						<CaseArticle
							classes="eCommerce__problems__article"
							titleClasses="eCommerce__problems__article-title"
							articleTitle="Problem and Solution"
							articleText="Our team took a part in development but our field of work was on the server side (backend). The best option in programming languages for this particular case were PHP and Javascript as they would provide a stable functionality and data structure."
						/>
						<div className="eCommerce__problems__ipadWrapper">
							<img src={problemsIpad} className="image" alt="" />
						</div>
						<div className="eCommerce__problems__imageWrapper eCommerce__problems__aboutUs">
							<img src={aboutUsImage} className="image" alt="" />
						</div>
						<div className="eCommerce__problems__imageWrapper eCommerce__problems__formWrapper">
							<img src={formImage} className="image" alt="" />
							<img src={rocket} className="eCommerce__problems__rocket" alt="" />
						</div>
					</div>
					<div className="eCommerce__review">
						<img src={reviewBackground} className="eCommerce__review__background" alt="" />
						<CaseReview
							name="Sandra March"
							position="CTO"
							text="“Alfa-Solution has brought software and technologies to a new level on our website. They have helped us become a leader in our industry, because in the era of technology, technically sound websites guarantee more than 80% success.”"
						/>
					</div>
					<CaseFooter image={headAndFooterImage} classes="eCommerce__footer" imageClasses="eCommerce__footer__image" />
				</div>
			</div>
		</>
	);
};
