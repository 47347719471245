import React from 'react';
import PropTypes from 'prop-types';

export const WorkflowSteps = ({ stepsStyle, caption, text }) => {
	return (
		<>
			<div className={`workflow-content__cards-${stepsStyle}`}>
				<h3 className="workflow-content__cards-caption">{caption}</h3>
				<span className="workflow-content__cards-text" dangerouslySetInnerHTML={{ __html: text }} />
			</div>
		</>
	);
};

WorkflowSteps.propTypes = {
	stepsStyle: PropTypes.oneOf(['ideation', 'estimation', 'proposal', 'closure']),
	caption: PropTypes.string,
	text: PropTypes.string,
};
