import React from 'react';
import PropTypes from 'prop-types';
import './scss/Emoji.scss';

export default function Emoji({ emojiStyle, title, image }) {
	return (
		<div className={`emoji emoji-${emojiStyle}`}>
			<img src={image} alt="" />
			<span>{title}</span>
		</div>
	);
}

Emoji.propTypes = {
	emojiStyle: PropTypes.string,
	title: PropTypes.string,
	image: PropTypes.string,
};
