import React from 'react';
import { ReactSVG } from 'react-svg';
import headOuterElipse from '../../../../../assets/img/portfolio-case-img/crmSystem/headImage-outerElipse.png';
import headInnerElipse from '../../../../../assets/img/portfolio-case-img/crmSystem/headImage-innerElipse.png';
import headOuterElipseMob from '../../../../../assets/img/portfolio-case-img/crmSystem/headImageOuterElipse_mob.png';
import hpElite from '../../../../../assets/img/portfolio-case-img/crmSystem/hpElite.png';
import arrow from '../../../../../assets/img/portfolio-case-img/crmSystem/arrow.png';
import macStudio from '../../../../../assets/img/portfolio-case-img/crmSystem/macStudio.png';
import descriptionImage from '../../../../../assets/img/portfolio-case-img/crmSystem/descriptionImage.png';
import teamScreen from '../../../../../assets/img/portfolio-case-img/crmSystem/teamScreen.png';
import todoList from '../../../../../assets/img/portfolio-case-img/crmSystem/toDoList.png';
import memberScreen from '../../../../../assets/img/portfolio-case-img/crmSystem/memberScreen.png';
import projectsScreen from '../../../../../assets/img/portfolio-case-img/crmSystem/projectScreen.png';
import vacancyScreen from '../../../../../assets/img/portfolio-case-img/crmSystem/vacancyScreen.png';
import notification from '../../../../../assets/img/portfolio-case-img/crmSystem/notification.png';
import problemsElipse from '../../../../../assets/img/portfolio-case-img/crmSystem/problemsElipse.png';
import pixelBook from '../../../../../assets/img/portfolio-case-img/crmSystem/pixelBook.png';
import footerImage from '../../../../../assets/img/portfolio-case-img/crmSystem/footerImage.png';
import gear from '../../../../../assets/img/portfolio-case-img/gear.svg';
import '../../Case.scss';
import './CRMSystemCase.scss';
import { CaseTitle } from '../../components/CaseTitle';
import { CaseArticle } from '../../components/CaseArticle';
import { CaseFooter } from '../../components/CaseFooter';

export const CRMSystem = () => {
	return (
		<>
			<div className="caseContainer crmSystem__caseContainer">
				<img src={headOuterElipse} className="crmSystem__headImage__outerElipse" alt="" />
				<img src={headOuterElipseMob} className="crmSystem__headImage__outerElipse_mob" alt="" />
				<img src={headInnerElipse} className="crmSystem__headImage__innerElipse" alt="" />
				<CaseTitle title="CRM/ERP system" />
				<div className="caseBody crmSystem__body">
					<div className="crmSystem__mainImages__wrapper">
						<div className="crmSystem__mainImages__hpEliteWrapper">
							<img src={arrow} className="crmSystem__mainImages__hpEliteWrapper__arrow" alt="" />
							<img src={hpElite} className="image" alt="" />
						</div>
						<div className="crmSystem__mainImages__macStudioWrapper">
							<img src={macStudio} className="image" alt="" />
						</div>
					</div>
					<div className="crmSystem__description">
						<ReactSVG src={gear} className="crmSystem__description__gear_small" />
						<ReactSVG src={gear} className="crmSystem__description__gear_big" />
						<CaseArticle
							withBackground
							classes="crmSystem__description__article"
							articleTitle="Description"
							articleText="Businesses looking to automate core business processes typically look at two main software solutions, enterprise resource planning (ERP) and customer relationship management (CRM). ERP helps companies run successful businesses by connecting their financial and operational systems to a central database, while CRM helps manage how customers interact with their businesses.
CRM/ERP systemis our internal product and one of the few on the market that combines the main IT processes into one huge platform.
"
						/>
						<div className="crmSystem__description__imageWrapper">
							<img src={descriptionImage} className="image" alt="" />
						</div>
					</div>
					<div className="crmSystem__problems">
						<img src={problemsElipse} className="crmSystem__problems__elipse" alt="" />
						<CaseArticle
							withBackground
							classes="crmSystem__problems__article"
							articleTitle="Problem and Solution"
							articleText="The goal of the system: Improve business relationships to grow your business. All platform tools help you manage customer relationships across the entire customer lifecycle,  at every project managing, developing, sales processing, accounting, human resourcing and customer service interaction.
This solution unifies ERP and CRM
systems.
"
						/>
						<div className="crmSystem__problems__imagesWrapper">
							<img src={teamScreen} className="crmSystem__problems__image crmSystem__problems__teamScreen" alt="" />
							<img src={todoList} className="crmSystem__problems__image crmSystem__problems__todoList" alt="" />
							<img
								src={projectsScreen}
								className="crmSystem__problems__image crmSystem__problems__projectsScreen"
								alt=""
							/>
							<img
								src={vacancyScreen}
								className="crmSystem__problems__image crmSystem__problems__vacancyScreen"
								alt=""
							/>
							<img src={memberScreen} className="crmSystem__problems__image crmSystem__problems__memberScreen" alt="" />
							<img src={notification} className="crmSystem__problems__image crmSystem__problems__notification" alt="" />
						</div>
					</div>
					<div className="crmSystem__advantages">
						<CaseArticle
							withBackground
							classes="crmSystem__advantages__article"
							articleTitle="Advantages"
							articleText="ERP systems with CRM built on the platform offer a number of advantages. Unified ERP and CRM systems tend to be less expensive than purchasing point solutions individually, and the unified data model means all information is updated in real-time, without having to wait for batch uploads or middleware connections. Systems built for ERP from the ground up are better able to handle transactional processes — that means simpler programming, customizations and third-party tools."
						/>
						<div className="crmSystem__advantages__pixelBookWrapper">
							<img src={pixelBook} className="image" alt="" />
						</div>
					</div>
					<CaseFooter image={footerImage} imageClasses="crmSystem__footer__image" classes="crmSystem__footer" />
				</div>
			</div>
		</>
	);
};
