import React, { useEffect } from 'react';
import Technologies from './components/Technologies';
import Workflow from './components/Workflow';
import BottomWrapper from '../../ui/BottomWrapper/BottomWrapper';

export const TechnologiesPage = () => {
	useEffect(() => {
		document.title = 'Technologies';
	});

	return (
		<>
			<section className="technologies">
				<Technologies />
			</section>
			<BottomWrapper>
				<Workflow />
			</BottomWrapper>
		</>
	);
};
