import ACTION_TYPES from './types';
import { blogArticle } from '../../pages/BlogPage/consts';

export const sendFormRequest = ({ form } = {}) => ({
	type: ACTION_TYPES.SEND_FORM_REQUEST,
	payload: { form },
});

export const sendFormSuccess = ({ form } = {}) => ({
	type: ACTION_TYPES.SEND_FORM_SUCCESS,
	payload: { form },
});

export const sendFormError = ({ error }) => ({
	type: ACTION_TYPES.SEND_FORM_ERROR,
	payload: { error },
});

export const fetchPost = () => {
	return async dispatch => {
		dispatch({ type: ACTION_TYPES.FETCH_POST, payload: blogArticle });

		// FOR API
		// const response = await fetch(
		// 	'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40a-sol',
		// );
		// const json = await response.json();
		// dispatch({ type: ACTION_TYPES.FETCH_POST, payload: json });
	};
};
