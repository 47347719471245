import portImg1 from '../../assets/img/portfolio-img/portfolio1-img.png';
import portImg2 from '../../assets/img/portfolio-img/portfolio2-img.png';
import portImg3 from '../../assets/img/portfolio-img/portfolio3-img.png';
import portImg4 from '../../assets/img/portfolio-img/portfolio4-img.png';
import portImg5 from '../../assets/img/portfolio-img/portfolio5-img.png';
import portImg6 from '../../assets/img/portfolio-img/portfolio6-img.png';
import portImg7 from '../../assets/img/portfolio-img/portfolio7-img.png';
import portImg8 from '../../assets/img/portfolio-img/portfolio8-img.png';
import portImg9 from '../../assets/img/portfolio-img/portfolio9-img.png';
import portImg10 from '../../assets/img/portfolio-img/portfolio10-img.png';
import portImg11 from '../../assets/img/portfolio-img/portfolio11-img.png';

export const cases = [
	{
		imgSrc: portImg1,
		headerTitle: 'Custom-consensus blockchain',
		articleText: 'Run a blockchain on a cell phone? We can do that!',
		altImg: 'Blockchain',
		id: 1,
	},
	{
		imgSrc: portImg2,
		headerTitle: 'Charity platform',
		articleText: 'The smallest act of kindness is worth more than the grandest intention',
		altImg: 'Charity platform',
		id: 2,
	},
	{
		imgSrc: portImg3,
		headerTitle: 'E-commerce solution',
		articleText: 'Easy to use for retail and wholesale customers and staff',
		altImg: 'E-commerce',
		id: 3,
	},
	{
		imgSrc: portImg4,
		headerTitle: 'Administrative application',
		articleText: 'Can withstand high loads and capable of serving up to 3000 users at once',
		altImg: 'Administrative app',
		id: 4,
	},
	{
		imgSrc: portImg5,
		headerTitle: 'Rental application',
		articleText: 'Optimized for faster load speeds and convenience',
		altImg: 'Application',
		id: 5,
	},
	{
		imgSrc: portImg6,
		headerTitle: 'Financial aggregator',
		articleText: 'We can find best offers out there',
		altImg: 'Financial aggregator',
		id: 6,
	},
	{
		imgSrc: portImg7,
		headerTitle: 'CRM System',
		articleText: 'CRM system is combines the main IT processes into one huge platform',
		altImg: 'CRM System',
		id: 7,
	},
	{
		imgSrc: portImg8,
		headerTitle: 'Personal growth courses',
		articleText: 'Created to promote personal growth courses',
		altImg: 'Personal growth courses landing page',
		id: 8,
	},
	{
		imgSrc: portImg9,
		headerTitle: 'E-commerce',
		articleText: 'Easy to use for retail and wholesale customers and staff',
		altImg: 'E-commerce',
		id: 9,
	},
	{
		imgSrc: portImg10,
		headerTitle: 'ERP System',
		articleText: 'ERP standardizes and streamline business processes across services, sales, finance etc.',
		altImg: 'ERP System',
		id: 10,
	},
	{
		imgSrc: portImg11,
		headerTitle: 'Instant low-cost retail payments',
		articleText: 'Blockchain solution for Instant low-cost retail payments',
		altImg: 'Blockchain payment technologies',
		id: 11,
	},
	{
		imgSrc: portImg11,
		headerTitle: 'AI technologies',
		articleText: 'AI Recruitment for fast and effective selection of employees in the company',
		altImg: 'AI technologies',
		id: 12,
	},
];
