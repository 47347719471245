import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './Contact-UsPage.scss';
import * as ActionCreators from '../../redux/actions';
import { PopUpCase } from './PopUpCase/PopUpCase';
import CaptionH2 from '../../ui/caption/CaptionH2';
import { ContactUsForm } from './components/ContactUsForm';

export const ContactUsPage = () => {
	const [isHidden, setIsHidden] = useState(true);
	const [inputValues, setInputValues] = useState([]);
	const dispatch = useDispatch();
	const sendFormAction = form => dispatch(ActionCreators.sendFormRequest({ form }));

	useEffect(() => {
		document.title = 'Contact Us';
	}, []);

	const openCase = () => {
		setIsHidden(!isHidden);
		if (isHidden) {
			document.body.classList.add('scroll');
		} else {
			document.body.classList.remove('scroll');
		}
	};

	const submitHandler = () => {
		const name = inputValues[0];
		const phone = inputValues[1];
		const email = inputValues[2];
		const message = inputValues[3];
		if (name || phone || email || message) {
			sendFormAction({
				name,
				phone,
				email,
				message,
			});
		}
	};

	return (
		<>
			<div className="contactContainer container">
				<CaptionH2 title="Contact us" classes="contactContainer__heading" styles="first" />
				<ContactUsForm onClickOpenCase={openCase} onSubmit={submitHandler} setInputValues={setInputValues} />
			</div>
			{isHidden ? null : <PopUpCase openCase={openCase} popUpText="Thanks for choosing us." />}
		</>
	);
};
