import React from 'react';
import PropTypes from 'prop-types';
import { slideInUp } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import { popUpCase } from './consts';

import { CloseCase } from './components/CloseCase';

export const PopUpCase = ({ openCase, id }) => {
	const SlideInUp = styled.div`
		width: 100%;
		animation: 0.5s ${keyframes`${slideInUp}`};
	`;

	const popup = popUpCase.filter(link => id === link.index);
	return (
		<>
			<div className="caseContainer__wrapper">
				<SlideInUp>
					<CloseCase openCase={openCase} />

					<div className="caseContainer__inner-wrapper">{popup[0].case()}</div>
				</SlideInUp>
			</div>
		</>
	);
};

PopUpCase.propTypes = {
	openCase: PropTypes.func,
	id: PropTypes.number,
};
