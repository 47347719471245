export const StepsData = [
	{
		caption: '1 - Ideation',
		text:
			'When a client is starting working with us we provide him with a universal idea. We schedule our first' +
			'	call to discuss all of the complexity and potential issues of the project. After we find the best way	for' +
			' realization',
		stepsStyle: 'ideation',
	},
	{
		caption: '2 - Estimation',
		text:
			'After discussing the idea we direct this information to our development team' +
			' where they can start working on providing us with an estimate of the time to develop a discussed project',
		stepsStyle: 'estimation',
	},
	{
		caption: '3 - Proposal',
		text:
			'At this stage, we will be sending you the result of the estimation and closest date of the start, and' +
			'	what also it can include:' +
			'<ul>' +
			'<li>' +
			'Business Analytic first stage of work it’s of courses BA' +
			'</li>' +
			'<li>' +
			'Architecture' +
			'</li>' +
			'<li>' +
			'UI/UX design' +
			'</li>' +
			'<li>' +
			'Development' +
			'</li>' +
			'<li>' +
			'Testing' +
			'</li>' +
			'</ul>',
		stepsStyle: 'proposal',
	},
	{
		caption: '4 - Closure',
		text:
			'We hand over the project and all its assets to you and awaiting your feedback on our development and' +
			'	further cooperation and support for your product',
		stepsStyle: 'closure',
	},
];
