import React from 'react';
import PropTypes from 'prop-types';
import './CardImg.scss';

export const CardImg = ({ srcImg, altImg }) => <img className="cards-wrapper__cardImg" src={srcImg} alt={altImg} />;

CardImg.propTypes = {
	srcImg: PropTypes.string.isRequired,
	altImg: PropTypes.string,
};
