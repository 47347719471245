import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../ui/button/button';
import './scss/Tags.scss';

export default function Tags({ title }) {
	return (
		<>
			<Button mainClass="tag" title={title} />
		</>
	);
}

Tags.propTypes = {
	title: PropTypes.string,
};
