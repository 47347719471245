import React from 'react';
import headElipse from '../../../../../assets/img/portfolio-case-img/CharityPlatform/headElipse.png';
import headAndFooterImage from '../../../../../assets/img/portfolio-case-img/CharityPlatform/HeadAndFooterImage.png';
import upperVector from '../../../../../assets/img/portfolio-case-img/CharityPlatform/upperVector.png';
import lowerVector from '../../../../../assets/img/portfolio-case-img/CharityPlatform/lowerVector.png';
import descriptionImage from '../../../../../assets/img/portfolio-case-img/CharityPlatform/description-img.png';
import problemsElipse from '../../../../../assets/img/portfolio-case-img/CharityPlatform/problemsElipse.png';
import problemsMacbook from '../../../../../assets/img/portfolio-case-img/CharityPlatform/problemsMacbook.png';
import michigan from '../../../../../assets/img/portfolio-case-img/CharityPlatform/michigan.png';
import worldVista from '../../../../../assets/img/portfolio-case-img/CharityPlatform/vorldvista.png';
import ybCase from '../../../../../assets/img/portfolio-case-img/CharityPlatform/ybCase.png';
import foundation from '../../../../../assets/img/portfolio-case-img/CharityPlatform/foundation.png';
import statistic from '../../../../../assets/img/portfolio-case-img/CharityPlatform/statisticImage.png';
import achieves from '../../../../../assets/img/portfolio-case-img/CharityPlatform/achieves.png';
import rocket from '../../../../../assets/img/portfolio-case-img/rocket.png';
import reviewBackground from '../../../../../assets/img/portfolio-case-img/CharityPlatform/reviewBackground.png';
import { CaseTitle } from '../../components/CaseTitle';
import { CaseArticle } from '../../components/CaseArticle';
import { CaseReview } from '../../components/CaseReview';
import '../../Case.scss';
import './CharityPlatformCase.scss';

import { CaseFooter } from '../../components/CaseFooter';

export const CharityPlatformCase = () => {
	return (
		<div className="caseContainer">
			<img className="headImage charityPlatform__headImage" src={headElipse} alt="" />
			<div className="caseHeader">
				<CaseTitle title="Charity platform" />
			</div>
			<div className="caseBody charityPlatform__body">
				<div className="charityPlatform__caseAbout">
					<CaseArticle
						classes="charityPlatform__caseAbout__article"
						textClasses="charityPlatform__caseAbout__article-text"
						withBackground
						articleText="This case shows development of a particular charity website that carries informative content and guides for anyuser who is looking to make any sort of donation."
					/>
					<div className="charityPlatform__caseAbout__imageWrapper">
						<img
							src={upperVector}
							className="charityPlatform__caseAbout__vector charityPlatform__caseAbout__vector__upper"
							alt=""
						/>
						<img src={headAndFooterImage} className="charityPlatform__caseAbout__image" alt="" />
						<img
							src={lowerVector}
							className="charityPlatform__caseAbout__vector charityPlatform__caseAbout__vector__lower"
							alt=""
						/>
					</div>
				</div>
				<div className="charityPlatform__description">
					<div className="charityPlatform__description__imageWrapper">
						<img src={descriptionImage} className="image" alt="" />
					</div>
					<CaseArticle
						withBackground
						classes="charityPlatform__description__article"
						articleTitle="Description"
						articleText="Platform idea was to store all available contacts of any charity representatives and activities. Through a builtin payment system any user is eligible to make transactions to a desired charity activity that are listed onthe website. And finally users were given an option to pinpoint or attend a charity activity using the mapthat contained all active charity movements at the specific time with an additional option to contact representatives of that specific event."
					/>
				</div>
				<div className="charityPlatform__problems">
					<img src={problemsElipse} className="charityPlatform__problems__background" alt="" />
					<CaseArticle
						classes="charityPlatform__problems__article"
						titleClasses="charityPlatform__problems__article-title"
						textClasses="charityPlatform__problems__article-text"
						articleTitle="Problem and Solution"
						articleText="Website was developed with a help of such programming languages as:PHPVue.jsLaravelThose languages were chosen as a website carried out mostly as an informative platform and therefore this setof languages were more than suitable to provide stable performance for the website."
					/>
					<div className="charityPlatform__problems__macbook">
						<img src={problemsMacbook} className="image" alt="" />
						<img src={michigan} className="charityPlatform__problems__macBook__smallImage michigan" alt="" />
						<img src={worldVista} className="charityPlatform__problems__macBook__smallImage worldVista" alt="" />
						<img src={ybCase} className="charityPlatform__problems__macBook__smallImage ybCase" alt="" />
						<img src={foundation} className="charityPlatform__problems__macBook__smallImage foundation" alt="" />
					</div>
					<div className="charityPlatform__problems__statisticWrapper">
						<img src={statistic} className="image" alt="" />
					</div>
					<div className="charityPlatform__problems__achievesWrapper">
						<img src={rocket} className="charityPlatform__problems__achievesWrapper__rocket" alt="" />
						<img src={achieves} className="image" alt="" />
					</div>
				</div>
				<div className="charityPlatform__review">
					<img src={reviewBackground} className="charityPlatform__review__background" alt="" />
					<CaseReview
						name="Peter Buss"
						position="CFO"
						text="Alpha Solutions has become an important partner for us. Thanks to them, our site is developed using the latest technologies that allow the user to quickly find and get help from us. We will definitely cooperate in the future, as it is this company that embodies all our requirements."
					/>
				</div>
				<CaseFooter image={headAndFooterImage} />
			</div>
		</div>
	);
};
