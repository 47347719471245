import React from 'react';
import SectionTop from './components/SectionSoftware';
import Slider from '../../components/Slider/Slider';
import SectionBottom from './components/SectionAbout';
import './HomePage.scss';
import BottomWrapper from '../../ui/BottomWrapper/BottomWrapper';
// import { NewYearModal } from './components/NewYearModal/NewYearModal';

export const HomePage = () => {
	// const [isHidden, setIsHidden] = useState(false);
	// const openCase = () => {
	// 	setIsHidden(true);
	// 	if (isHidden) {
	// 		document.body.classList.add('scroll');
	// 	} else {
	// 		document.body.classList.remove('scroll');
	// 	}
	// };
	// insert this into the component
	// {!isHidden && <NewYearModal openCase={openCase} />}
	return (
		<>
			<div className="software">
				<SectionTop />
			</div>

			<div className="slider-wrapper">
				<Slider />
			</div>

			<BottomWrapper>
				<SectionBottom />
			</BottomWrapper>
		</>
	);
};
