import React from 'react';
import { ReactSVG } from 'react-svg';
import headElipse from '../../../../../assets/img/portfolio-case-img/AiRecruitment/headElipse.png';
import headImage from '../../../../../assets/img/portfolio-case-img/AiRecruitment/headImage.png';
import descriptionImage from '../../../../../assets/img/portfolio-case-img/AiRecruitment/descriptionImage.png';
import problemsElipse from '../../../../../assets/img/portfolio-case-img/AiRecruitment/problemsElipse.png';
import getStarted from '../../../../../assets/img/portfolio-case-img/AiRecruitment/getStarted.png';
import customerReviews from '../../../../../assets/img/portfolio-case-img/AiRecruitment/customerReviews.png';
import detailsLaptop from '../../../../../assets/img/portfolio-case-img/AiRecruitment/detailsLaptop.png';
import signUpImage from '../../../../../assets/img/portfolio-case-img/AiRecruitment/signUp.png';
import loginImage from '../../../../../assets/img/portfolio-case-img/AiRecruitment/login.png';
import detailsLine from '../../../../../assets/img/portfolio-case-img/AiRecruitment/detailsLine.png';
import reviewElipse from '../../../../../assets/img/portfolio-case-img/AiRecruitment/reviewElipse.png';
import footerImage from '../../../../../assets/img/portfolio-case-img/AiRecruitment/footerImage.png';
import gearSvg from '../../../../../assets/svg/gear.svg';
import { CaseTitle } from '../../components/CaseTitle';
import { CaseArticle } from '../../components/CaseArticle';
import './AiRecruitment.scss';
import { CaseReview } from '../../components/CaseReview';

import { CaseFooter } from '../../components/CaseFooter';

export const AiRecruitment = () => {
	return (
		<div className="caseContainer">
			<img src={headElipse} className="headImage" alt="" />
			<div className="caseHeader">
				<CaseTitle title="AI Recruitment" />
			</div>
			<div className="caseBody">
				<div className="aiRecruitment__about">
					<CaseArticle
						classes="aiRecruitment__about__article"
						withBackground
						articleText="This case allows you to match your candidates
with the perfect jobs using AI. We have stabilized and improved the recruiting process and enabled companies to quickly and efficiently find the ideal job for your candidates."
					/>
					<div className="aiRecruitment__about__image-wrapper">
						<img src={headImage} className="image" alt="" />
					</div>
				</div>
				<div className="aiRecruitment__description">
					<CaseArticle
						classes="aiRecruitment__description__article"
						withBackground
						articleTitle="Description"
						articleText="The client was looking for an opportunity to automate the work of his company by creating an extension that, using AI, would select ideal candidates for job vacancies. AI Recruitment generates matches from relevant live job opportunities in your target client market"
					/>
					<div className="aiRecruitment__description__image-wrapper">
						<img src={descriptionImage} className="image" alt="" />
					</div>
				</div>
				<div className="aiRecruitment__problems caseProblems">
					<img src={problemsElipse} className="aiRecruitment__problems__background caseProblems__background" alt="" />
					<CaseArticle
						classes="caseProblems__article"
						titleClasses="caseProblems__article-title"
						articleTitle="Problem and Solution"
						articleText="The main components of this project were such features as: Adding candidates and their resumes. AI analyzes their key characteristics: experience, skill set, seniority, etc.
 Getting targeted job matches
 AI Recruitment generates matches from current vacancies in the target client market.
 Monetization of new opportunities
 Access key contact information and use artificial intelligence and talking points to ensure the perfect presentation.

Working as a team of designers and programmers, we managed to create an ideal source of AI recruitment for companies."
					/>
					<div className="aiRecruitment__problems__images">
						<div className="aiRecruitment__problems__image-wrapper">
							<ReactSVG
								src={gearSvg}
								className="aiRecruitment__problems__gear aiRecruitment__problems__getStarted-gear"
							/>
							<img src={getStarted} className="image" alt="" />
						</div>
						<div className="aiRecruitment__problems__reviews aiRecruitment__problems__image-wrapper">
							<ReactSVG
								src={gearSvg}
								className="aiRecruitment__problems__gear aiRecruitment__problems__gear aiRecruitment__problems__reviews-gear_white"
							/>
							<ReactSVG src={gearSvg} className="aiRecruitment__problems__gear aiRecruitment__problems__reviews-gear" />
							<img src={customerReviews} className="image" alt="" />
						</div>
					</div>
				</div>
				<div className="aiRecruitment__details">
					<img src={detailsLine} className="aiRecruitment__details__line" alt="" />
					<div className="aiRecruitment__details__laptop">
						<img src={detailsLaptop} className="image" alt="" />
					</div>
					<div className="aiRecruitment__details__content">
						<CaseArticle
							articleTitle="Details"
							classes="aiRecruitment__details__article"
							withBackground
							articleText="AI Recruitment technology ensures automation of the work of IT companies by selecting candidates as efficiently and quickly as possible. AI has long become an integral part of many companies and is already being actively implemented in them."
						/>
						<div className="aiRecruitment__details__content__images">
							<img src={signUpImage} className="image" alt="" />
							<img src={loginImage} className="image" alt="" />
						</div>
					</div>
				</div>
				<div className="aiRecruitment__review">
					<img src={reviewElipse} className="aiRecruitment__review__background" alt="" />
					<CaseReview
						name="Peter Buss"
						position="CFO"
						text="Alpha Solutions has become an important partner for us. Thanks to them, our site is developed using the latest technologies that allow the user to quickly find and get help from us. We will definitely cooperate in the future, as it is this company that embodies all our requirements."
					/>
				</div>
				<CaseFooter image={footerImage} />
			</div>
		</div>
	);
};
