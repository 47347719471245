import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import illPageNotFound from '../../assets/svg/ill_404-error.svg';
import './NotFoundPage.scss';
import Button from '../../ui/button/button';
import CaptionH3 from '../../ui/caption/CaptionH3';

export const NotFoundPage = () => {
	useEffect(() => {
		document.title = 'Page Not Found';
	});

	const history = useHistory();

	function handleClick() {
		history.push('/');
	}

	return (
		<div className="pageNotFound ">
			<div className="container">
				<ReactSVG className="pageNotFound-image" src={illPageNotFound} />
				<div className="pageNotFound-text">
					<CaptionH3 title="Oops... Something went wrong" classes="pageNotFound-text__title" />
					<div className="pageNotFound-text__description">
						<span>This page may be unavailable for a short time or deleted</span>
						<Button
							mainClass="primary"
							secClass="big"
							title="Go to homepage"
							classes="pageNotFound-text__description-btn"
							onClick={() => handleClick()}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
