import React from 'react';
import { ReactSVG } from 'react-svg';
import { CaseTitle } from '../../components/CaseTitle';
import { CaseArticle } from '../../components/CaseArticle';
import headElipse from '../../../../../assets/img/portfolio-case-img/Estate/headElipse.png';
import vector from '../../../../../assets/img/portfolio-case-img/Estate/vector.svg';
import vectorMob from '../../../../../assets/img/portfolio-case-img/Estate/vector-mob.svg';
import headAndFooterImage from '../../../../../assets/img/portfolio-case-img/Estate/headAndFooterImage.png';
import bodyElipse from '../../../../../assets/img/portfolio-case-img/Estate/bodyElipse.png';
import descriptionImage from '../../../../../assets/img/portfolio-case-img/Estate/descriptionImage.png';
import reactNativeImage from '../../../../../assets/img/portfolio-case-img/Estate/reactNative.png';
import reactNativeMob from '../../../../../assets/img/portfolio-case-img/Estate/reactNative-mob.png';
import expressImage from '../../../../../assets/img/portfolio-case-img/Estate/expressJs.png';
import webScreen1 from '../../../../../assets/img/portfolio-case-img/Estate/webScreen1.png';
import webScreen2 from '../../../../../assets/img/portfolio-case-img/Estate/webScreen2.png';
import webScreen3 from '../../../../../assets/img/portfolio-case-img/Estate/webScreen3.png';
import comment from '../../../../../assets/img/portfolio-case-img/Estate/comment.png';
import mobileScreens from '../../../../../assets/img/portfolio-case-img/Estate/mobileScreens.png';
import reviewElipse from '../../../../../assets/img/portfolio-case-img/Estate/reviewElipse.png';
import '../../Case.scss';
import './EstateCase.scss';
import { CaseReview } from '../../components/CaseReview';
import { CaseFooter } from '../../components/CaseFooter';

export const Estate = () => {
	return (
		<>
			<div className="caseContainer">
				<img src={headElipse} className="estate__headImage" alt="" />
				<img src={bodyElipse} className="estate__elipse" alt="" />
				<div className="caseHeader">
					<CaseTitle classes="estate__caseTitle" title="Real estate web application" />
				</div>
				<div className="caseBody estate__body">
					<div className="estate__caseAbout">
						<ReactSVG src={vector} className="estate__caseAbout__vector" />
						<ReactSVG src={vectorMob} className="estate__caseAbout__vector-mob" />
						<CaseArticle
							withBackground
							classes="estate__caseAbout__article"
							articleText="This particular case we had to create an analog application of AirBnb rental application. The main functionality and the concept were clearly taken from an original application with an unique design."
						/>
						<div className="estate__caseAbout__imageWrapper">
							<img src={headAndFooterImage} className="image" alt="" />
						</div>
					</div>
					<div className="estate__description">
						<div className="estate__description__imageWrapper">
							<img src={descriptionImage} className="image" alt="" />
						</div>
						<CaseArticle
							withBackground
							classes="estate__description__article"
							articleTitle="Description"
							articleText="Application users have the option to rent out their accommodations to travelers in whole or inpart. The site provides a platform for establishing contact between a host and a guest, and is also responsible for processing transactions."
						/>
					</div>
					<div className="estate__problemsAndScreens__wrapper">
						<div className="estate__problems">
							<CaseArticle
								withBackground
								classes="estate__problems__article"
								articleTitle="Problem and Solution"
								articleText="The application was built using Express.js and React Native programming languages to allow cross-platform use of the application on various devices."
							/>
							<img
								src={expressImage}
								className="estate__problems__technology estate__problems__technology-express"
								alt=""
							/>
							<img
								src={reactNativeImage}
								className="estate__problems__technology estate__problems__technology-react"
								alt=""
							/>
							<img
								src={reactNativeMob}
								className="estate__problems__technology estate__problems__technology-react-mob"
								alt=""
							/>
						</div>
						<div className="estate__webScreens">
							<h2 className="estate__webScreens__title"> Web screens</h2>
							<img src={webScreen1} className="estate__webScreens-image estate__webScreens-first" alt="" />
							<img src={comment} className="estate__webScreens-image estate__webScreens-comment" alt="" />
							<img src={webScreen2} className="estate__webScreens-image estate__webScreens-second" alt="" />
							<img src={webScreen3} className="estate__webScreens-image estate__webScreens-third" alt="" />
						</div>
					</div>
					<div className="estate__mobileScreens">
						<h2 className="estate__mobileScreens__title">Mobile screens</h2>
						<div className="estate__mobileScreens__imageWrapper">
							<img src={mobileScreens} className="image" alt="" />
						</div>
					</div>
					<div className="estate__caseReview">
						<img src={reviewElipse} className="estate__caseReview__elipse" alt="" />
						<CaseReview
							name="Cristopher Milan"
							position="CTO"
							text="“Alpha Solutions is a great partner. These developers are up to date on the latest technology, they know what they are doing, and want to deliver high quality code. Alpha Solutions allowed me as a CTO to focus on fuether developing our products.”"
						/>
					</div>
					<CaseFooter image={headAndFooterImage} />
				</div>
			</div>
		</>
	);
};
