import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { SliderData } from './utils/SliderData';
import './Slider.scss';
import { SlideCard } from './SlideCard/SlideCard';
import CaptionH2 from '../../ui/caption/CaptionH2';

SwiperCore.use([Pagination, Navigation]);
const Slider = () => {
	function addZero(num) {
		return num > 9 ? num : `0${num}`;
	}

	return (
		<>
			<CaptionH2 styles="first" title="Services we provide" />
			<Swiper
				slidesPerView={1}
				spaceBetween={30}
				speed={0}
				pagination={{
					el: '.homeSlider__pagination',
					type: 'fraction',
					currentClass: 'homeSlider__currentIndex',
					totalClass: 'homeSlider__slidesCount',
					formatFractionCurrent: addZero,
					formatFractionTotal: addZero,
				}}
				navigation
				className="homeSlider homeSlider__desktop"
			>
				{SliderData.map(item => {
					const { srcImg, imgClass, captionTitle, text } = item;
					return (
						<>
							<SwiperSlide className="homeSlide__wrapper" key={Math.random()}>
								<SlideCard
									srcImg={srcImg}
									imgClass={imgClass}
									captionTitle={captionTitle}
									text={text}
									key={Math.random()}
								/>
							</SwiperSlide>
						</>
					);
				})}
			</Swiper>
			<Swiper
				slidesPerView="auto"
				pagination={{ el: '.homeSlider__pagination-mobile', bulletActiveClass: 'homeSlider__bullet-active' }}
				className="homeSlider homeSlider__mobile"
			>
				{SliderData.map(item => {
					const { srcImg, imgClass, captionTitle, text } = item;
					return (
						<>
							<SwiperSlide className="homeSlide__wrapper" key={Math.random()}>
								<SlideCard
									srcImg={srcImg}
									imgClass={imgClass}
									captionTitle={captionTitle}
									text={text}
									key={Math.random()}
								/>
							</SwiperSlide>
						</>
					);
				})}

				<div className="homeSlider__pagination-mobile" />
			</Swiper>
		</>
	);
};

export default Slider;
