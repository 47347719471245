import illWebApps from 'assets/svg/Slider/ill_full-construction-web-apps.svg';
import illStrategic from 'assets/svg/Slider/ill_strategic-planning.svg';
import illWebDev from 'assets/svg/Slider/ill_web-development.svg';
import illAppDev from 'assets/svg/Slider/ill_application-development.svg';
import illBlockchain from 'assets/svg/Slider/ill-blockchain-security.svg';
import illWebSecurity from 'assets/svg/Slider/ill_web-security.svg';
import illDevConsult from 'assets/svg/Slider/ill_development-consulting.svg';
import illDevSupp from 'assets/svg/Slider/ill_ongoing-development-support.svg';

export const SliderData = [
	{
		srcImg: illWebApps,
		imgClass: 'webApps',
		captionTitle: 'Full construction web apps',
		text:
			'We can assist you on every single step of your journey from idea to live project. Our experience and' +
			' individual approach will guarantee you a successful launch',
	},
	{
		srcImg: illStrategic,
		imgClass: 'strategic',
		captionTitle: 'Strategic planning',
		text:
			'You want to know when things will be done, and we can tell you. This will allow to cut up to 30% of' +
			' development costs',
	},
	{
		srcImg: illWebDev,
		imgClass: 'webDev',
		captionTitle: 'Web Development',
		text:
			'Web Development If your business is not on the internet, then your business will be out of business” –' +
			' Bill Gates',
	},
	{
		srcImg: illAppDev,
		imgClass: 'appDev',
		captionTitle: 'Application Development',
		text:
			'Having fundamental experience in creating digital solutions, we develop applications that exceed your' +
			' expectations and tailored exactly to your target audience',
	},
	{
		srcImg: illBlockchain,
		imgClass: 'blockchain',
		captionTitle: 'Blockchain Development',
		text:
			'We are successfully introducing blockchain technology into business. Using solutions based on blockchain' +
			' technologies, your project can become a more convenient and flexible tool for all market participants',
	},
	{
		srcImg: illWebSecurity,
		imgClass: 'webSecurity',
		captionTitle: 'Web Security',
		text:
			'Our task is to ensure the security of your Internet business by eliminating product vulnerabilities and' +
			' controlling possible risks',
	},
	{
		srcImg: illDevConsult,
		imgClass: 'devConsult',
		captionTitle: 'Development Consulting',
		text:
			'We are ready to help you with advice at any stage of your project. There is no limit to perfection, and' +
			' we can find out what limits you',
	},
	{
		srcImg: illDevSupp,
		imgClass: 'devSupp',
		captionTitle: 'Ongoing development support',
		text: 'Software development is a never ending process. You should always grow, and we will be here to help',
	},
];
