import React from 'react';
import PropTypes from 'prop-types';
import './scss/ContentArticle.scss';

export default function ContentArticle({ text, caption }) {
	return (
		<>
			<div className="article-content__container">
				{caption}
				<span dangerouslySetInnerHTML={{ __html: text }} />
			</div>
		</>
	);
}

ContentArticle.propTypes = {
	caption: PropTypes.object,
	text: PropTypes.string,
};
