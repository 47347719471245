import React, { useState } from 'react';
import { TechnologiesData, TechnologiesDataBottom } from './utils/TechnologiesData';
import './scss/Technologies.scss';
import CaptionH2 from '../../../ui/caption/CaptionH2';
import { Cards } from './utils/Cards';

export default function Technologies() {
	const [currentId, setCurrentId] = useState(null);

	const handleClickMob = id => {
		setCurrentId(id);
	};

	return (
		<>
			<div className="container">
				<div className="technologies-content">
					<CaptionH2 styles="first" title="Technologies" />

					<div className="technologies-content__cards">
						<div className="technologies-content__cards-top">
							{TechnologiesData.map(link => {
								const { classes, captionTitle, id, imgSrc, cardText } = link;
								return (
									<>
										<Cards
											imgSrc={imgSrc}
											classes={classes}
											cardText={cardText}
											id={id}
											handleClickMob={handleClickMob}
											currentId={currentId}
											captionTitle={captionTitle}
											key={Math.random()}
										/>
									</>
								);
							})}
						</div>

						<div className="technologies-content__cards-bottom">
							{TechnologiesDataBottom.map(link => {
								const { classes, captionTitle, id, imgSrc, cardText } = link;
								return (
									<>
										<Cards
											imgSrc={imgSrc}
											classes={classes}
											cardText={cardText}
											id={id}
											handleClickMob={handleClickMob}
											currentId={currentId}
											captionTitle={captionTitle}
											key={Math.random()}
										/>
									</>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
