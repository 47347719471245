import React from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './scss/Card.scss';
import CaptionH3 from '../../ui/caption/CaptionH3';
import Button from '../../ui/button/button';
import Icon from '../../ui/button/icon/icon';
import arrow from '../../assets/svg/IconBtn/arrow-right.svg';

const Card = ({
	captionTitle,
	imgSrc,
	imgClass,
	cardText,
	currentId,
	setCurrentId,
	id,
	handleClickMob,
	show,
	setShow,
}) => {
	const cardClass = classNames({
		card: true,
		'active-card': currentId === id,
		showText: show && currentId === id,
	});

	const handleClick = id => {
		setShow(!show);
		setCurrentId(id);
	};

	const styleTxt = {
		maxHeight: '2000px',
	};
	const styleBtn = {
		display: 'none',
	};

	const styledTxt = show ? styleTxt : null;
	const styledBtn = show && id === currentId ? styleBtn : null;

	return (
		<>
			<div className={cardClass} onClick={show ? handleClick : null} onMouseLeave={show ? handleClick : null}>
				<CaptionH3 title={captionTitle} classes="cooperation" />
				<Button mainClass="secondary" title="Read more" classes="technologies-btn" onClick={() => handleClickMob(id)} />
				<ReactSVG className={`card-img card-img__${imgClass}`} src={imgSrc} />
				{/* eslint-disable-next-line react/no-danger */}
				<span className="card-text" style={styledTxt} dangerouslySetInnerHTML={{ __html: cardText }} />

				<div className="readMore-wrapper" style={styledBtn}>
					<Button
						title="Read more"
						mainClass="whiteLink"
						classes="readMore-wrapper__btn"
						icon={<Icon imageUrl={arrow} imgId="arrow-right" imgClassName="arrowWhite" />}
						onClick={() => handleClick(id)}
					/>
				</div>
			</div>
		</>
	);
};

export default Card;

Card.propTypes = {
	captionTitle: PropTypes.string,
	imgSrc: PropTypes.string,
	imgClass: PropTypes.oneOf(['brand', 'outstaff', 'referral']),
	cardText: PropTypes.string,
	currentId: PropTypes.any,
	setCurrentId: PropTypes.func,
	id: PropTypes.number,
	handleClickMob: PropTypes.func,
	show: PropTypes.bool,
	setShow: PropTypes.func,
};
