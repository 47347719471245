export const InputData = [
	{
		type: 'text',
		name: 'userName',
		placeholder: 'Your name',
		id: 1,
	},
	{
		type: 'text',
		name: 'userNumber',
		placeholder: 'Your phone number',
		id: 2,
	},
	{
		type: 'email',
		name: 'userEmail',
		placeholder: 'E-mail',
		id: 3,
	},
];
