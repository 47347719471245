import React from 'react';
import PropTypes from 'prop-types';
import ImgHeader from './components/ImgHeader';
import TitleArticle from './components/TitleArticle';
import { Card } from '../../PortfolioPage/Card/Card';
import EndOfArticle from './components/EndOfArticle';
import Tags from './components/Tags';
import SocialLinks from './components/SocialLinks';

export const ContentArticle = ({
	srcImgHeader,
	captionArticle,
	titleText,
	headerTitle,
	cardsEntity,
	resultCaption,
	resultText,
	tagsTitle,
}) => {
	return (
		<>
			<ImgHeader srcImg={srcImgHeader} altImg="header" />
			<div className="container article-content">
				<TitleArticle captionArticle={captionArticle} text={titleText} />
				{cardsEntity.map(link => {
					return (
						<Card
							containerClass="article-cards__container"
							headerTitle={headerTitle}
							articleText={link.text}
							imgSrc={link.img}
							altImg="Office"
						/>
					);
				})}

				<EndOfArticle captionTitle={resultCaption} text={resultText} />
				<div className="article-content__container article-content__container-tags">
					{tagsTitle.map(link => {
						return <Tags title={link} />;
					})}
				</div>

				<SocialLinks />
			</div>
		</>
	);
};

ContentArticle.propTypes = {
	srcImgHeader: PropTypes.any,
	captionArticle: PropTypes.string,
	titleText: PropTypes.string,
	headerTitle: PropTypes.string,
	resultCaption: PropTypes.string,
	resultText: PropTypes.string,
	tagsTitle: PropTypes.string,
	cardsEntity: PropTypes.any,
};
