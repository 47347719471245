import React from 'react';
import './scss/SectionAbout.scss';
import picLaptop from '../../../assets/img/pic_laptop-with-picture.png';
import Emoji from './Emoji';
import picBiceps from '../../../assets/img/emoji/pic_flexed-biceps.png';
import picNerdFace from '../../../assets/img/emoji/pic_nerd-face.png';
import picCat from '../../../assets/img/emoji/pic_smiling-cat-with-heart-eyes.png';
import picFace from '../../../assets/img/emoji/pic_smiling-face-with-halo.png';
import picSpeech from '../../../assets/img/emoji/pic_speech-balloon.png';
import CaptionH2 from '../../../ui/caption/CaptionH2';

export default function SectionBottom() {
	return (
		<div className="container">
			<div className="content-container">
				<div className="content-container__text">
					<CaptionH2 styles="second" title="About us" classes="homePage" />
					<span>
						We are a young and skilled team. Our main goal is to provide great service in software development. A-sol -
						is all about constant communication, high-quality code, and responsive management. With us, you can control
						the speed of your project and all stages of development. We offer both - an outstaff foundation and
						outsource
					</span>
				</div>
				<div className="content-container__pictures">
					<img className="content-container__pictures-img" src={picLaptop} alt="" />
				</div>
				<Emoji emojiStyle="fourth" image={picCat} title="Web-site" />
				<Emoji emojiStyle="fifth" image={picSpeech} />
				<Emoji emojiStyle="sixth" image={picBiceps} />
				<Emoji emojiStyle="seventh" image={picFace} />
				<Emoji emojiStyle="eighth" image={picNerdFace} title="Developers" />
			</div>
		</div>
	);
}
