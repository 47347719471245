import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Logo from './items/logo';
import NavBar from './items/navbar';
import './scss/header.scss';

export default function Header() {
	const [isScrolled, setIsScrolled] = useState(false);
	const [isActive, setIsActive] = useState(false);

	const scrollHandler = () => {
		if (window.scrollY > 5) {
			setIsScrolled(true);
		} else {
			setIsScrolled(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', scrollHandler);
		return () => {
			window.removeEventListener('scroll', scrollHandler);
		};
	});

	const handleClick = () => {
		setIsActive(!isActive);
		document.body.classList.toggle('scroll');
	};

	const headerClass = classNames({
		header: true,
		'header-scroll': isScrolled,
		active: isActive,
	});

	return (
		<header className={headerClass}>
			<div className="header-content">
				<div className="container">
					<div className="header-nav">
						<Logo />
						<NavBar isActive={isActive} handleClick={handleClick} />
					</div>
				</div>
			</div>
		</header>
	);
}
