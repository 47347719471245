import React from 'react';
import PropTypes from 'prop-types';
import CaptionH3 from '../../../../ui/caption/CaptionH3';
import ContentArticle from './ContentArticle';

export default function EndOfArticle({ text, captionTitle }) {
	return (
		<>
			<ContentArticle caption={<CaptionH3 title={captionTitle} margin="second" />} text={text} />
		</>
	);
}

EndOfArticle.propTypes = {
	text: PropTypes.string,
	captionTitle: PropTypes.string,
};
