import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Packages-Of-CooperationPage.scss';
import image3 from '../../assets/svg/ill_referral-program.svg';
import image2 from '../../assets/svg/ill_work-with-project-ongoing.svg';
import image from '../../assets/svg/ill_work-with-project-from-scratch.svg';
import Button from '../../ui/button/button';
import CaptionH2 from '../../ui/caption/CaptionH2';
import Card from '../../components/packagesOfCooperation/Card';

export const PackagesOfCooperationPage = () => {
	useEffect(() => {
		document.title = 'Packages Of Cooperation';
	});

	const [show, setShow] = useState(false);
	const [currentId, setCurrentId] = useState(null);

	const handleClickMob = id => {
		setCurrentId(id);
	};

	const history = useHistory();
	const cards = [
		{
			captionTitle: 'Brand new standalone projects',
			imgSrc: image,
			imgClass: 'brand',
			cardText:
				'We provide a full package of services for developing products from scratch. Our qualified team is' +
				'responsible for a full-cycle development:  ' +
				'<br/>' +
				'<br/>' +
				'Analysis' +
				'<br/>' +
				'At the start of our cooperation, we collect the most vital project information to analyze product potential' +
				'and its value on the market which will allow us to give an approximate estimate and costs the project will' +
				'require.' +
				'<br/>' +
				'<br/>' +
				'Architecture' +
				'<br/>' +
				'We create the most up to date structure of the project and choose the most fitting and valid Frameworks in' +
				'order to assure the stability of the project.' +
				'<br/>' +
				'<br/>' +
				'Design\\Prototype' +
				'<br/>' +
				'We provide clients with a great team of ux\\ui designers who can create a unique design' +
				'based on clients expectations and market evaluation. Following with an interactive prototype with' +
				'animations and a preview of the functionality.' +
				'<br/>' +
				'<br/>' +
				'Developing' +
				'<br/>' +
				'Our core team specializes in a variety of Frameworks that we use to create products:' +
				'React, Vue.js, Node.js, PHP. These are the most reliable and stable frameworks that are both applicable to' +
				' simple and complex projects.' +
				'<br/>' +
				'<br/>' +
				'Testing' +
				'<br/>' +
				'Our QA team will make sure the product is fully functional ensuring stability and usability before the release.' +
				'<br/>' +
				'<br/>' +
				'Release and support' +
				'<br/>' +
				'Our team will be responsible for deploying the project on time and monitoring the market reducing any' +
				'probability of issues to zero following with handing over all of the assets to the client.' +
				'<br/>' +
				'<br/>' +
				'We also provide support for a project after the release where we will be responsible for constant updates' +
				' and maintenance of the project.',
			id: 1,
		},
		{
			captionTitle: 'Outstaff Projects',
			imgSrc: image2,
			imgClass: 'outstaff',
			cardText:
				'We offer our skilled developers as outstaff employees to assist on your ongoing or brand new projects.' +
				'<br/>' +
				'<br/>' +
				'Our team is more than capable of evaluating and reviewing the code of your project in order to start right where it is at its current state.' +
				'<br/>' +
				'<br/>' +
				'Analysis' +
				'<br/>' +
				'We analyze the project at its stage it was provided with the information we will receive from the client,' +
				'our dev will be able to review the code and project features and goals in order to provide a valid' +
				' estimate.' +
				'<br/>' +
				'<br/>' +
				'Developing\\Monitoring' +
				'<br/>' +
				'We provide highly skilled devs that can ensure the project will continue its lifecycle.' +
				'<br/>' +
				'<br/>' +
				'Our oustaff package includes a Project manager to ensure the progress will be monitored and reported to' +
				' client if the 3rd party team does not have one.' +
				'<br/>' +
				'<br/>' +
				'Testing' +
				'<br/>' +
				'If the 3d party requires QA specialists we can provide one in order to ensure the product is functional and' +
				' brought up to clients expectations.' +
				'<br/>' +
				'<br/>' +
				'Release' +
				'<br/>' +
				'Upon the final stages of the project, we transfer all of the code and assets to the client.',
			id: 2,
		},
		{
			captionTitle: 'Referral program',
			imgSrc: image3,
			imgClass: 'referral',
			cardText:
				'We provide bonuses to referrals as we are always open to more projects to be added to our Portfolio' +
				'<br/>' +
				'<br/>' +
				'How the system works:' +
				'<br/>' +
				'You can refer us to anyone who is looking for a development team for:' +
				'<br/>' +
				'MVP' +
				'<br/>' +
				'Brand new or ongoing project' +
				'<br/>' +
				'Code review' +
				'<br/>' +
				'<br/>' +
				'Upon successful sign of the contract, the referee will receive bonuses',
			id: 3,
		},
	];
	return (
		<>
			<div className="cooperation-wrapper container">
				<div className="caption-wrapper">
					<CaptionH2 title="Packages of cooperation" styles="second" />
					<span className="caption-wrapper__text">There are 3 options with us</span>
				</div>
				<div className="cards-wrapper">
					{cards.map(link => {
						const { captionTitle, imgSrc, imgClass, cardText, id } = link;

						return (
							<Card
								captionTitle={captionTitle}
								imgSrc={imgSrc}
								imgClass={imgClass}
								cardText={cardText}
								key={id}
								handleClickMob={handleClickMob}
								currentId={currentId}
								setCurrentId={setCurrentId}
								id={id}
								show={show}
								setShow={setShow}
							/>
						);
					})}
				</div>
				<Button
					classes="button"
					mainClass="primary"
					secClass="big"
					title="Contact us"
					onClick={() => history.push('/contact-us')}
				/>
			</div>
		</>
	);
};
