import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './SlideCard.scss';
import CaptionH3 from '../../../ui/caption/CaptionH3';
import Button from '../../../ui/button/button';
import Icon from '../../../ui/button/icon/icon';
import arrow from '../../../assets/svg/IconBtn/arrow-right.svg';

export const SlideCard = ({ srcImg, imgAlt, captionTitle, text, imgClass }) => {
	const history = useHistory();

	return (
		<>
			<div className="homeSlide container">
				<div className="homeSlide__image-wrapper">
					<div className="homeSlide__image-bg" />
					<img src={srcImg} className={`homeSlide__image ${imgClass}`} alt={imgAlt} />
				</div>
				<div className="homeSlide__content">
					<div className="homeSlider__pagination" />
					<CaptionH3 classes="homeSlide__title" margin="second" title={captionTitle} />

					<p className="homeSlide__text">{text}</p>
					<div className="homeSlide__buttons-wrapper">
						<Button
							classes="homeSlide__button"
							title="Order service"
							mainClass="primary"
							secClass="small"
							onClick={() => history.push('/contact-us')}
						/>
						<Button
							classes="homeSlide__button"
							title="See cases"
							mainClass="secondary"
							secClass="simple"
							icon={<Icon imageUrl={arrow} imgId="arrow-right" imgClassName="arrow-main" />}
							onClick={() => history.push('/portfolio')}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

SlideCard.propTypes = {
	srcImg: PropTypes.string,
	imgAlt: PropTypes.string,
	captionTitle: PropTypes.string,
	text: PropTypes.string,
	imgClass: PropTypes.string,
};
