import React from 'react';
import PropTypes from 'prop-types';
import './scss/ImgHeader.scss';

export default function ImgHeader({ srcImg, altImg }) {
	return (
		<>
			<div className="header-image">
				<img src={srcImg} alt={altImg} />
			</div>
		</>
	);
}

ImgHeader.propTypes = {
	srcImg: PropTypes.string,
	altImg: PropTypes.string,
};
