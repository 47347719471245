import axios from 'axios';
import { BASE_URL } from '../config';

const http = axios.create({
	baseURL: `${BASE_URL}`,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	},
});

export const sendForm = body => http.post('/contact', body);
