import React from 'react';
import PropTypes from 'prop-types';
import CaptionH3 from '../../../../ui/caption/CaptionH3';
import Button from '../../../../ui/button/button';
import Icon from '../../../../ui/button/icon/icon';
import eye from '../../../../assets/svg/IconBtn/eye.svg';
import './CardText.scss';

export const CardText = ({ headerTitle, articleText, openCase }) => (
	<>
		<CaptionH3 title={headerTitle} classes="portfolio__card-title" margin="first" />
		<span className="cards-text" dangerouslySetInnerHTML={{ __html: articleText }} />
		<Button
			classes="portfolio-btn"
			title="Open case"
			mainClass="secondary"
			secClass="simple"
			icon={<Icon imageUrl={eye} imgId="eye" imgClassName="eye" />}
			onClick={openCase}
		/>
	</>
);

CardText.propTypes = {
	headerTitle: PropTypes.string,
	articleText: PropTypes.string.isRequired,
	openCase: PropTypes.func.isRequired,
};
