import React from 'react';
import PropTypes from 'prop-types';
import '../MVPPage.scss';

export const MVPCard = ({ title, imgSrc, cardImgClasses, rootImgClasses, alt, classes }) => {
	return (
		<>
			<div className={`mvpContainer__areas__item ${classes}`}>
				<h3 className="mvpContainer__areas__item__title">{title}</h3>
				<div className="mvpContainer__areas__item__imageWrapper">
					<img
						className={`mvpContainer__areas__item__image ${rootImgClasses} ${cardImgClasses}`}
						src={imgSrc}
						alt={alt}
					/>
				</div>
			</div>
		</>
	);
};

MVPCard.propTypes = {
	title: PropTypes.string,
	imgSrc: PropTypes.string,
	cardImgClasses: PropTypes.string,
	rootImgClasses: PropTypes.string,
	alt: PropTypes.string,
	classes: PropTypes.string,
};
