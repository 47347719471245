import React from 'react';
import PropTypes from 'prop-types';
import './buttons.scss';

export default function Button({ mainClass, secClass, onClick, title, thirdClass, icon, classes, btnType }) {
	return (
		<button
			className={`btn btn-${mainClass} btn-${mainClass}__${secClass} btn-${mainClass}__${secClass}-${thirdClass} ${classes}`}
			onClick={onClick}
			type={btnType === 'submit' ? 'submit' : 'button'}
		>
			{title}
			{icon}
		</button>
	);
}

Button.propTypes = {
	mainClass: PropTypes.oneOf(['primary', 'secondary', 'medium', 'tag', 'link', 'whiteLink']),
	secClass: PropTypes.oneOf(['big', 'small', 'simple']),
	thirdClass: PropTypes.oneOf(['order', 'arrow']),
	title: PropTypes.string.isRequired,
	icon: PropTypes.element,
	classes: PropTypes.string,
	onClick: PropTypes.func,
	btnType: PropTypes.string,
	disabled: PropTypes.bool,
};
