import React from 'react';
import PropTypes from 'prop-types';

export default function Copy({ classes }) {
	return (
		<div className={`copyright ${classes}`}>
			<span>© Copyright A-SOL 2021. All rights reserved</span>
		</div>
	);
}

Copy.propTypes = {
	classes: PropTypes.string,
};
