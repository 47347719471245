/* eslint rest-spread-spacing: ["error", "never"] */

import produce from 'immer';
import ACTION_TYPES from '../actions/types';

const initialState = {
	forms: [],
	posts: [],
	isFetching: false,
	error: null,
};

const handlers = {
	[ACTION_TYPES.SEND_FORM_REQUEST]: produce(draft => {
		draft.isFetching = true;
	}),

	[ACTION_TYPES.SEND_FORM_SUCCESS]: produce((draft, action) => {
		const { form } = action.payload;
		draft.forms.push(form);
		draft.isFetching = false;
	}),

	[ACTION_TYPES.SEND_FORM_ERROR]: produce((draft, action) => {
		const { error } = action.payload;
		draft.error = error;
		draft.isFetching = false;
	}),
};

export default function formReducer(state = initialState, action) {
	const { type } = action;
	if (handlers[type]) {
		return handlers[type](state, action);
	}
	return state;
}

export const postReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.FETCH_POST:
			return { ...state, posts: action.payload };
		default:
			return state;
	}
};
