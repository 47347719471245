import React from 'react';
import PropTypes from 'prop-types';
import { CardImg } from './components/CardImg';
import { CardText } from './components/CardText';
import './Card.scss';

export const Card = ({ imgSrc, headerTitle, articleText, altImg, openCase, containerClass = '' }) => (
	<article className={`portContainer__artContainer ${containerClass}`}>
		<div className="portContainer__artContainer__image">
			<CardImg srcImg={imgSrc} altImg={altImg} />
		</div>
		<div className="portContainer__artContainer__twoItems">
			<CardText articleText={articleText} headerTitle={headerTitle} openCase={openCase} />
		</div>
	</article>
);

Card.propTypes = {
	imgSrc: PropTypes.string.isRequired,
	headerTitle: PropTypes.string.isRequired,
	articleText: PropTypes.string.isRequired,
	altImg: PropTypes.string,
	openCase: PropTypes.func,
	containerClass: PropTypes.string,
};
