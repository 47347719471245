import React from 'react';
import PropTypes from 'prop-types';
import CaptionH2 from '../../../../ui/caption/CaptionH2';
import ContentArticle from './ContentArticle';

export default function TitleArticle({ text, captionArticle }) {
	return (
		<>
			<CaptionH2 styles="first" title={captionArticle} classes="article-content__header" />
			<ContentArticle text={text} />
		</>
	);
}

TitleArticle.propTypes = {
	text: PropTypes.string,
	captionArticle: PropTypes.string,
};
