import React from 'react';
import './scss/CardsImg.scss';
import PropTypes from 'prop-types';

export default function CardsImg({ srcImg, altImg, onClick }) {
	return (
		<>
			<div className="blog-wrapper__cards-img" onClick={onClick}>
				<img src={srcImg} alt={altImg} />
			</div>
		</>
	);
}

CardsImg.propTypes = {
	srcImg: PropTypes.string.isRequired,
	altImg: PropTypes.string,
	onClick: PropTypes.func,
};
