import eCommerce from '../../assets/img/mvp/e-commerce.png';
import sport from '../../assets/img/mvp/sport.png';
import logistics from '../../assets/img/mvp/logistics.png';
import medicine from '../../assets/img/mvp/medicine.png';
import banking from '../../assets/img/mvp/banking.png';
import hotels from '../../assets/img/mvp/hotels.png';
import golf from '../../assets/img/mvp/golf.png';
import agriculture from '../../assets/img/mvp/agriculture.png';
import realEstate from '../../assets/img/mvp/realEstate.png';

export const areas = {
	firstRow: [
		{
			id: 1,
			imgSrc: eCommerce,
			cardImgClasses: 'mvpContainer__areas__eCommerce__image',
			title: 'E-commerce',
			alt: 'eCommerce',
		},
		{
			id: 2,
			imgSrc: sport,
			cardImgClasses: 'mvpContainer__areas__sport__image',
			title: 'Sport',
			alt: 'sport',
		},
	],
	secondRow: [
		{
			id: 1,
			imgSrc: logistics,
			cardImgClasses: '',
			title: 'Logistics',
			alt: 'logistics',
		},
		{
			id: 2,
			imgSrc: medicine,
			cardImgClasses: '',
			title: 'Medicine',
			alt: 'medicine',
		},
		{
			id: 3,
			imgSrc: banking,
			cardImgClasses: '',
			title: 'Banking',
			alt: 'banking',
		},
		{
			id: 4,
			imgSrc: hotels,
			cardImgClasses: '',
			title: 'Hotels',
			alt: 'hotels',
		},
	],
	thirdRow: [
		{
			id: 1,
			imgSrc: golf,
			cardImgClasses: 'mvpContainer__areas__golf__image',
			title: 'Golf',
			alt: 'golf',
		},
		{
			id: 2,
			imgSrc: agriculture,
			cardImgClasses: '',
			title: 'Agriculture',
			alt: 'agriculture',
		},
		{
			id: 3,
			imgSrc: realEstate,
			cardImgClasses: '',
			title: 'Real estate',
			alt: 'real estate',
		},
	],
};
