import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextArea } from '../../../ui/input/TextArea';
import Button from '../../../ui/button/button';
import Input from '../../../ui/input/input';
import { VALIDATION_SCHEMA } from './utils/ValidationSchemas';
import { InputData } from './utils/InputData';

export const ContactUsForm = ({ onSubmit, onClickOpenCase, setInputValues }) => {
	const {
		register,
		handleSubmit,
		reset,
		watch,
		clearErrors,
		formState: { errors, isValid, isDirty, isSubmitSuccessful },
	} = useForm({ resolver: yupResolver(VALIDATION_SCHEMA), mode: 'onTouched' });
	const fieldValues = watch(['userName', 'userNumber', 'userEmail', 'message']);

	const submitHandler = () => {
		onSubmit();
	};

	const sendClickHandler = () => {
		if (isValid === true) {
			onClickOpenCase();
			clearErrors();
			setInputValues(fieldValues);
		}
	};

	useEffect(() => {
		if (isSubmitSuccessful === true) {
			reset();
		}
	}, [isSubmitSuccessful]);

	return (
		<form className="contactContainer__wrapper" onSubmit={handleSubmit(submitHandler)}>
			<div className="contactContainer__wrapper__input">
				<div className="contactContainer__wrapper__input__right">
					{InputData.map((item, index) => {
						const { type, name, placeholder } = item;
						return (
							<div className="input-field__wrapper">
								<Input
									classes="contactContainer__wrapper__input__inp_style"
									styles="field"
									type={type}
									name={name}
									placeholder={placeholder}
									register={register}
									isDirty={isDirty}
									value={fieldValues[index]}
									key={name}
									errors={errors}
								/>
								{errors[name] && <span className="contactContainer__wrapper__input-error">{errors[name].message}</span>}
							</div>
						);
					})}
				</div>

				<div className="contactContainer__wrapper__input__msgWrapper">
					<TextArea placeholder="Message" isDirty={isDirty} register={register} value={fieldValues[3]} />
				</div>
			</div>
			<Button
				classes="contactContainer__wrapper__btn"
				mainClass="primary"
				secClass="big"
				title="Send"
				btnType="submit"
				onClick={sendClickHandler}
			/>
		</form>
	);
};

ContactUsForm.propTypes = {
	onSubmit: PropTypes.func,
	onClickOpenCase: PropTypes.func,
	setInputValues: PropTypes.func,
};
