import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import './ScrollUp.scss';
import IconScroll from '../../assets/svg/IconBtn/scroll-to-the-top.svg';

export default function ScrollUp() {
	const [scrollBtn, setScrollBtn] = useState(false);
	const { pathname } = useLocation();

	const scrollBtnClick = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const scrollBtnHandler = () => {
		if (window.scrollY > 800) {
			setScrollBtn(true);
		} else {
			setScrollBtn(false);
		}
	};

	useEffect(() => {
		scrollBtnClick();
		window.addEventListener('scroll', scrollBtnHandler);
		return () => {
			window.removeEventListener('scroll', scrollBtnHandler);
		};
	}, [pathname]);

	return (
		<div className={classNames('scrollup', { 'scrollup-show': scrollBtn })} onClick={scrollBtnClick}>
			<ReactSVG src={IconScroll} />
		</div>
	);
}
