import React from 'react';
// import { ReactSVG } from 'react-svg';
import './icon.scss';
import PropTypes from 'prop-types';

export default function Icon({ imageUrl, imgId, imgClassName }) {
	return (
		// <ReactSVG className={view} src={imageUrl} />
		<svg className={`icons ${imgClassName}`}>
			<use xlinkHref={`${imageUrl}#${imgId}`} />
		</svg>
	);
}

Icon.propTypes = {
	imageUrl: PropTypes.string.isRequired,
	imgId: PropTypes.string,
	imgClassName: PropTypes.oneOf(['arrow-black', 'arrow-right', 'arrow-main', 'arrow-thin', 'eye', 'arrowWhite']),
};
