import { Blockchain } from './Cases/BlockchainCase/Blockchain';
import { CharityPlatformCase } from './Cases/CharityPlatformCase/CharityPlatformCase';
import { ECommerce } from './Cases/ECommerceCase/ECommerceCase';
import { Administrative } from './Cases/AdministrativeCase/AdministrativeCase';
import { Estate } from './Cases/EstateCase/EstateCase';
import { FinAggregator } from './Cases/FinAggregator/FinAggregator';
import { CRMSystem } from './Cases/CRMSystemCase/CRMSystemCase';
import { CoursesCase } from './Cases/CoursesCase/CoursesCase';
import { ClothesEcommerceCase } from './Cases/ClothesECommerceCase/ClothesEcommerceCase';
import { ErpSystemCase } from './Cases/ErpSystemCase/ErpSystemCase';
import { BlockchainPaymentCase } from './Cases/BlockchainPaymentCase/BlockchainPaymentCase';
import { AiRecruitment } from './Cases/AiRecruitment/AiRecruitment';

export const popUpCase = [
	{
		index: 1,
		case: Blockchain,
	},
	{
		index: 2,
		case: CharityPlatformCase,
	},
	{
		index: 3,
		case: ECommerce,
	},
	{
		index: 4,
		case: Administrative,
	},
	{
		index: 5,
		case: Estate,
	},
	{
		index: 6,
		case: FinAggregator,
	},
	{
		index: 7,
		case: CRMSystem,
	},
	{
		index: 8,
		case: CoursesCase,
	},
	{
		index: 9,
		case: ClothesEcommerceCase,
	},
	{
		index: 10,
		case: ErpSystemCase,
	},
	{
		index: 11,
		case: BlockchainPaymentCase,
	},
	{
		index: 12,
		case: AiRecruitment,
	},
];
