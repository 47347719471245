import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CaptionH2 from '../../ui/caption/CaptionH2';
import './BlogPage.scss';
import '../../ui/input/input.scss';
import { SearchForm } from './components/SearchForm';
import { ArticlesBlock } from './components/ArticlesBlock';
import { fetchPost } from '../../redux/actions';

export const BlogPage = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const post = useSelector(state => state.posts.posts);
	const [openArticle, setOpenArticle] = useState(true);
	const [inputValue, setInputValue] = useState('');
	const [filteredArticles, setFilteredArticles] = useState([]);

	useEffect(() => {
		document.title = 'Blog';
		dispatch(fetchPost());
	}, []);

	useEffect(() => {
		setFilteredArticles(post);
	}, [post]);

	const clickHandler = id => {
		setOpenArticle(!openArticle);

		if (openArticle) {
			history.push(`/article/${id}`);
		}
	};

	const inputChange = e => {
		setInputValue(e.target.value);
	};

	const tagHandleClick = value => {
		setInputValue(value);
	};

	const filter = () => {
		if (!inputValue) {
			setFilteredArticles(post);
			return;
		}
		setFilteredArticles(
			filteredArticles.filter(item => {
				const { categories, title } = item;
				return (
					categories.join('').toLowerCase().includes(inputValue.trim().toLowerCase()) ||
					title.toLowerCase().includes(inputValue.trim().toLowerCase())
				);
			}),
		);
	};

	useEffect(() => {
		filter();
	}, [inputValue]);

	return (
		<>
			<div className="blog">
				<div className="container">
					<CaptionH2 styles="first" title="Blog" classes="blog-caption" />
					<SearchForm value={inputValue} onChange={inputChange} />
					<ArticlesBlock articles={filteredArticles} tagHandleClick={tagHandleClick} onClick={clickHandler} />
					{inputValue && !filteredArticles.length && <span className="search-false">No search result</span>}
				</div>
			</div>
		</>
	);
};

BlogPage.propTypes = {
	fetchPost: PropTypes.func,
};
