import * as yup from 'yup';

const NUMBER_REGEX = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;

export const NAME_SCHEMA = yup.string().trim().required('Field cannot be empty');
export const NUMBER_SCHEMA = yup
	.string()
	.trim()
	.required('Field cannot be empty')
	.matches(NUMBER_REGEX, 'Only numbers, also "+" or "-"')
	.min(10, 'Field too short')
	.max(20, 'Field too long');
export const EMAIL_SCHEMA = yup.string().trim().required('Field cannot be empty').email('Invalid email');

export const VALIDATION_SCHEMA = yup.object().shape({
	userName: NAME_SCHEMA,
	userNumber: NUMBER_SCHEMA,
	userEmail: EMAIL_SCHEMA,
});
