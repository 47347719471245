import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { fadeIn } from 'react-animations';
import styled, { keyframes } from 'styled-components';
import imgSent from '../../../assets/svg/ill_contacts-sent.svg';
import CaptionH3 from '../../../ui/caption/CaptionH3';
import Button from '../../../ui/button/button';
import './PopUpCase.scss';

export const PopUpCase = ({ openCase, popUpText }) => {
	const FadeIn = styled.div`
		animation: 0.5s ${keyframes`${fadeIn}`};
	`;

	return (
		<>
			<div className="popUp-wrapper">
				<FadeIn>
					<div className="popUp-container">
						<div className="popUp-container__content">
							<CaptionH3 title="Contacts sent!" margin="popUpSent" />
							<span className="popUp-container__content-text">{popUpText}</span>
							<Button
								title="Ok, thanks"
								mainClass="secondary"
								secClass="simple"
								onClick={openCase}
								classes="popUpSent"
							/>
						</div>

						<ReactSVG src={imgSent} className="popUp-container__img" />
					</div>
				</FadeIn>
			</div>
		</>
	);
};

PopUpCase.propTypes = {
	openCase: PropTypes.func,
	popUpText: PropTypes.string,
};
