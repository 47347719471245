import React from 'react';
import PropTypes from 'prop-types';
import './scss/CardsText.scss';
import CaptionH3 from '../../../../ui/caption/CaptionH3';
import Icon from '../../../../ui/button/icon/icon';
import Button from '../../../../ui/button/button';
import arrowThin from '../../../../assets/svg/IconBtn/arrow-right-thin.svg';

export default function CardsText({ headerTitle, articleText, onClick }) {
	function truncateText(text, start, len) {
		return text.length > len ? text.slice(start, len).replace(/\s*$/, '...') : text;
	}

	function toText(block) {
		const tag = document.createElement('div');
		tag.innerHTML = block;
		block = tag.innerText;
		return block;
	}

	return (
		<>
			<CaptionH3 title={headerTitle} margin="first" />
			<span className="blog-wrapper__cards-text">
				{`${truncateText(toText(articleText.replace(/»/g, '')), 0, 130)}`}
			</span>
			<Button
				mainClass="link"
				onClick={onClick}
				title="Read article"
				classes="blog-btn"
				icon={<Icon imageUrl={arrowThin} imgId="arrow-thin" imgClassName="arrow-thin" />}
			/>
		</>
	);
}

CardsText.propTypes = {
	headerTitle: PropTypes.string.isRequired,
	articleText: PropTypes.string,
	onClick: PropTypes.func,
};
