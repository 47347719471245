import imageFirst from '../../assets/img/blog-img/FirstCase/Cover.png';
import imageSecond from '../../assets/img/blog-img/SecondCase/Cover.png';
import imageThird from '../../assets/img/blog-img/ThirdCase/Cover.png';
import imageFourth from '../../assets/img/blog-img/FourthCase/Cover.png';
import imageFifth from '../../assets/img/blog-img/FifthCase/Cover.png';
import imageSixth from '../../assets/img/blog-img/SixthCase/Cover.png';

export const blogArticle = [
	{
		title: 'Startups and benefits of dedicated teams',
		content:
			' One of the main contributions of startups is how they have shaped the new workplace reality. With much more' +
			'agile, innovative, and creative thinking, they have managed to place people at the center of their' +
			'operations, starting with their own team, which has resulted from new positions and unique solutions.',
		thumbnail: imageFirst,
		id: 1,
		categories: ['Freelancer', 'Startup', 'Outsource'],
	},
	{
		title: 'Value of project management',
		content:
			'Let’s first have a look at who are Project managers and why they are such a big value to the organization processes and product development',
		thumbnail: imageSecond,
		id: 2,
		categories: ['PM', 'Team'],
	},
	{
		title: 'MVP and Its Value',
		content:
			'MVP is an abbreviation for ‘minimum viable product’, and refers to the initial stage of creating the first' +
			'workable (and saleable) version of your new business concept. It’s a concept that comes from a book called' +
			'‘The Lean Startup’ by Eric Reis and the basic principle is relatively simple.',
		thumbnail: imageThird,
		id: 3,
		categories: ['MVP', 'Product'],
	},
	{
		title: 'Java script and its frameworks variety',
		content:
			' JavaScript is a multi-paradigm language that supports event-driven, functional, and imperative' +
			'programming styles. JavaScript was initially used only for the client-side. However, these days, JavaScript' +
			'is used as a server-side programming language as well. To summarize, in just a simple sentence - JavaScript' +
			'is the language of the web.\n',
		thumbnail: imageFourth,
		id: 4,
		categories: ['Java Script', 'Framework', 'Code'],
	},
	{
		title: 'Development from scratch',
		content:
			'How to start building your product from scratch?\n' +
			'Building a product from scratch is a complex challenge. If you manage to develop a high-quality product ' +
			'tailored to the needs of your customers, it will definitely increase your chances of achieving your ' +
			'business goals. However, this is a very complex process with many threats.\n',
		thumbnail: imageFifth,
		id: 5,
		categories: ['Product', 'Business plan', 'Idea'],
	},
	{
		title: 'Agile, Scrum, Kanban - in search for the best project management methodology',
		content:
			"If you haven't learned in depth about project management methodologies, it can be hard to tell the difference " +
			'between a Kanban board and a Scrum board or to understand why the differences between Agile vs. Waterfall ' +
			"methodologies are important. And with so many similar terms floating around, it might seem like it doesn't " +
			'matter which project management method you choose.',
		thumbnail: imageSixth,
		id: 6,
		categories: ['Agile', 'Kanban', 'Scrum'],
	},
];
