import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import icSearch from '../../../assets/svg/ic_search.svg';

export const SearchForm = ({ value, onChange }) => {
	return (
		<>
			<form className="search">
				<ReactSVG src={icSearch} className="search-icon" />
				<input
					className="input input-search"
					id="search"
					type="search"
					placeholder="Try to use search"
					value={value}
					onChange={onChange}
				/>
			</form>
		</>
	);
};

SearchForm.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
};
