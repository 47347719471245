import React from 'react';
import PropTypes from 'prop-types';
import '../scss/socials.scss';
import { ReactSVG } from 'react-svg';

export default function Socials({ social, classes }) {
	return (
		<nav className={`socials ${classes}`}>
			{social.map(link => {
				const { src, id, route } = link;

				return (
					<a className="socials__item" href={route} target="blank" key={id}>
						<ReactSVG src={src} />
					</a>
				);
			})}
		</nav>
	);
}

Socials.propTypes = {
	classes: PropTypes.string,
	social: PropTypes.array,
	fill: PropTypes.oneOf(['secondary', 'dark', 'burger']),
};
