import React from 'react';
import { ReactSVG } from 'react-svg';
import { CaseTitle } from '../../components/CaseTitle';
import { CaseArticle } from '../../components/CaseArticle';
import { CaseFooter } from '../../components/CaseFooter';
import { CaseReview } from '../../components/CaseReview';
import headElipse from '../../../../../assets/img/portfolio-case-img/blockchain/headElipse.png';
import headAndFoooterImage from '../../../../../assets/img/portfolio-case-img/blockchain/headAndFooter-img.png';
import descriptionImage from '../../../../../assets/img/portfolio-case-img/blockchain/description-img.png';
import rocket from '../../../../../assets/img/portfolio-case-img/rocket.png';
import rocketMob from '../../../../../assets/img/portfolio-case-img/blockchain/rocketMob.png';
import problemsElipse from '../../../../../assets/img/portfolio-case-img/blockchain/problemsElipse.png';
import problemsAboutUs from '../../../../../assets/img/portfolio-case-img/blockchain/problemsAboutUs.png';
import problemsMain from '../../../../../assets/img/portfolio-case-img/blockchain/problemsMain.png';
import problemsVector from '../../../../../assets/img/portfolio-case-img/blockchain/vector.svg';
import problemsVectorMob from '../../../../../assets/img/portfolio-case-img/blockchain/problemsVectorMob.svg';
import detailsIpad from '../../../../../assets/img/portfolio-case-img/blockchain/detailsIpad.png';
import detailsIpadMob from '../../../../../assets/img/portfolio-case-img/blockchain/detailsIpadMob.png';
import createAccountImage from '../../../../../assets/img/portfolio-case-img/blockchain/detailsCreateAccount.png';
import signinImage from '../../../../../assets/img/portfolio-case-img/blockchain/deatailsSignin.png';
import reviewBackground from '../../../../../assets/img/portfolio-case-img/blockchain/reviewBackground.png';
import '../../Case.scss';
import './BlockchainCase.scss';

export const Blockchain = () => {
	return (
		<div className="caseContainer">
			<img className="headImage" src={headElipse} alt="headElipse" />
			<div className="caseHeader">
				<CaseTitle title="Custom-consensus blockchain" />
			</div>
			<div className="caseBody blockchain__body">
				<div className="blockchain__caseAbout">
					<CaseArticle
						classes="blockchain__caseAbout__article"
						textClasses="blockchain__caseAbout__article-text"
						withBackground
						articleText="This particular case shows a licensed digital asset that facilitates regulated and secure money transfer, selling and storing of digital assets.. App offers a range of services that allow users to utilize their assets in the most secure way."
					/>
					<div className="blockchain__caseAbout__imageWrapper">
						<img src={rocket} className="blockchain__caseAbout__rocket" alt="" />
						<img src={rocketMob} className="blockchain__caseAbout__rocket-mob" alt="" />
						<img className="image" src={headAndFoooterImage} alt="" />
					</div>
				</div>
				<div className="blockchain__description">
					<div className="blockchain__description__imageWrapper">
						<img src={descriptionImage} className="image" alt="description" />
					</div>
					<CaseArticle
						withBackground
						articleTitle="Description"
						classes="blockchain__description__article"
						articleText="Client was looking for a secure money transfer from wallet to wallet and that it would be user friendly and secure. This particular app is using  Golang and React Native programming languages.Client was insisting on secure end to end money transfer so the only and best solution was to use Blockchain."
					/>
				</div>
				<div className="blockChain__problems">
					<img src={problemsElipse} className="blockchain__problems__background" alt="" />
					<CaseArticle
						classes="blockchain__problems__article"
						titleClasses="blockchain__problems__article-title"
						articleTitle="Problem and Solution"
						articleText="Blockchain technology produces a structure of data with inherent security qualities. It's based on principles of cryptography, decentralization and consensus, which ensure trust in transactions. In most blockchains or distributed ledger technologies (DLT), the data is structured into blocks and each block contains a transaction or bundle of transactions. Each new block connects to all the blocks before it in a cryptographic chain in such a way that it's nearly impossible to tamper with. All transactions within the blocks are validated and agreed upon by a consensus mechanism, ensuring that each transaction is true and correct."
					/>
					<div className="blockchain__problems__inner-wrapper">
						<ReactSVG src={problemsVector} className="blockchain__problems__vector" />
						<ReactSVG src={problemsVectorMob} className="blockchain__problems__vector-mob" />
						<div className="blockchain__problems__image-wrapper">
							<img src={problemsAboutUs} alt="" className="image" />
						</div>
						<div className="blockchain__problems__image-wrapper">
							<img src={problemsMain} alt="" className="image" />
						</div>
					</div>
				</div>
				<div className="blockchain__details">
					<img src={detailsIpad} className="blockchain__details__ipad" alt="" />
					<img src={detailsIpadMob} className="blockchain__details__ipad-mob" alt="" />
					<div className="blockchain__details__content">
						<CaseArticle
							withBackground
							classes="blockchain__details__article"
							articleTitle="Details"
							articleText="Blockchain technology enables decentralization through the participation of members across a distributed network. There is no single point of failure and a single user cannot change the record of transactions."
						/>
						<div className="blockchain__details__images-wrapper">
							<img src={createAccountImage} alt="" className="blockchain__details__image" />
							<img src={signinImage} alt="" className="blockchain__details__image" />
						</div>
					</div>
				</div>
				<div className="blockchain__review">
					<img src={reviewBackground} className="blockchain__review__background" alt="" />
					<CaseReview
						name="Dan Brown"
						position="CEO"
						text="“For custom consensus blockchain the partnership with Alpha Solutions has taken our software development to the next level. Alpha Solutions  have helped us improve our processes and inspired us to use new software and and methodologies for our projects.”"
					/>
				</div>
			</div>
			<CaseFooter image={headAndFoooterImage} />
		</div>
	);
};
