import React from 'react';
import PropTypes from 'prop-types';
import { BlogCard } from '../BlogCard/BlogCard';

export const ArticlesBlock = ({ articles = [], onClick, tagHandleClick, articleAmount }) => {
	return (
		<>
			<div className="blog-wrapper">
				{articles
					.map(link => {
						const { id } = link;
						return <BlogCard blogData={link} key={id} onClick={() => onClick(id)} tagHandleClick={tagHandleClick} />;
					})
					.slice(0, articleAmount)}
			</div>
		</>
	);
};

ArticlesBlock.propTypes = {
	articles: PropTypes.array,
	onClick: PropTypes.func,
	tagHandleClick: PropTypes.func,
	articleAmount: PropTypes.number,
	blogPost: PropTypes.array,
};
