import React from 'react';
import PropTypes from 'prop-types';
import './TextArea.scss';

export const TextArea = ({ isDirty, value, onBlur, placeholder, onChange, register }) => {
	const addClassActive = isDirty && value.length > 0;

	return (
		<>
			<textarea
				className={`contactContainer__wrapper__input__msgWrapper__inp_msg contactContainer__wrapper__input__msgWrapper__${
					addClassActive ? 'active' : null
				}`}
				onBlur={onBlur}
				placeholder={placeholder}
				onChange={onChange}
				{...register('message')}
			/>
		</>
	);
};

TextArea.propTypes = {
	isDirty: PropTypes.bool,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	register: PropTypes.any,
};
