import React from 'react';
import { ReactSVG } from 'react-svg';
import { StepsData } from './utils/StepsData';
import './scss/Workflow.scss';
import CaptionH2 from '../../../ui/caption/CaptionH2';
import vectorPath from '../../../assets/svg/TechnplogiesPage/vector-path.svg';
import vectorPathMob from '../../../assets/svg/TechnplogiesPage/vector-path-mob.svg';
import { WorkflowSteps } from './utils/WorkflowSteps';

export default function Workflow() {
	return (
		<>
			<div className="container">
				<div className="workflow-content">
					<CaptionH2 styles="second" title="Workflow" />
					<span className="workflow-content__title">Steps of cooperation:</span>
					<div className="workflow-content__cards">
						<ReactSVG className="workflow-content__cards-vector" src={vectorPath} />
						<ReactSVG className="workflow-content__cards-vector_mob" src={vectorPathMob} />
						{StepsData.map(item => {
							const { caption, text, stepsStyle } = item;
							return <WorkflowSteps stepsStyle={stepsStyle} caption={caption} text={text} key={Math.random()} />;
						})}
					</div>
				</div>
			</div>
		</>
	);
}
