import React from 'react';
import { ReactSVG } from 'react-svg';
import headImage from '../../../../../assets/img/portfolio-case-img/Administrative/headElipse.png';
import gear from '../../../../../assets/img/portfolio-case-img/gear.svg';
import headAndFooterImage from '../../../../../assets/img/portfolio-case-img/Administrative/headAndFooterImage.png';
import descriptionImage from '../../../../../assets/img/portfolio-case-img/Administrative/descriptionImage.png';
import descriptionElipse from '../../../../../assets/img/portfolio-case-img/Administrative/descriptionElipse.png';
import problemsElipse from '../../../../../assets/img/portfolio-case-img/Administrative/problemsElipse.png';
import macStudio from '../../../../../assets/img/portfolio-case-img/Administrative/macStudio.png';
import laravel from '../../../../../assets/img/portfolio-case-img/Administrative/laravel.png';
import vue from '../../../../../assets/img/portfolio-case-img/Administrative/vue.png';
import vector from '../../../../../assets/img/portfolio-case-img/Administrative/vector.png';
import result1 from '../../../../../assets/img/portfolio-case-img/Administrative/result1.png';
import result2 from '../../../../../assets/img/portfolio-case-img/Administrative/result2.png';
import result3 from '../../../../../assets/img/portfolio-case-img/Administrative/result3.png';
import result3Mob from '../../../../../assets/img/portfolio-case-img/Administrative/result-mob.png';
import result4 from '../../../../../assets/img/portfolio-case-img/Administrative/result4.png';
import result5 from '../../../../../assets/img/portfolio-case-img/Administrative/result5.png';
import reviewElipse from '../../../../../assets/img/portfolio-case-img/Administrative/reviewElipse.png';
import '../../Case.scss';
import './AdministrativeCase.scss';
import { CaseTitle } from '../../components/CaseTitle';
import { CaseArticle } from '../../components/CaseArticle';
import { CaseReview } from '../../components/CaseReview';
import { CaseFooter } from '../../components/CaseFooter';

export const Administrative = () => {
	return (
		<>
			<div className="caseContainer">
				<img src={headImage} className="headImage administrative__headImage" alt="" />
				<ReactSVG src={gear} className="administrative__headGear" alt="" />
				<CaseTitle title="Administrative application" classes="administrative__caseTitle" />
				<div className="caseBody administrative__body">
					<div className="administrative__project__imageWrapper">
						<img src={headAndFooterImage} className="image" alt="" />
					</div>
					<div className="administrative__description">
						<img src={descriptionElipse} className="administrative__description__elipse" alt="" />
						<ReactSVG src={gear} className="administrative__description__gear" alt="" />
						<CaseArticle
							withBackground
							classes="administrative__description__article"
							articleTitle="Description"
							articleText="The aim was to create a suitable substitute for Jira management software for a small-sized company. As we allknow Jira practices selling and monetizing some of the core plugins that are crucial in managing and maintaining the project cycle."
						/>
						<div className="administrative__description__imageWrapper">
							<img src={descriptionImage} className="image" alt="" />
						</div>
					</div>
					<div className="administrative__problems">
						<img src={problemsElipse} className="administrative__problems__elipse" alt="" />
						<ReactSVG src={gear} className="administrative__problems__gear" alt="" />
						<CaseArticle
							withBackground
							classes="administrative__problems__article"
							titleClasses="administrative__problems__article-title"
							textClasses="administrative__problems__article-text"
							articleTitle="Problem and Solution"
							articleText="So we have come up with a similar comprehensive software that includes applications for taskscheduling, scheduling, price control, and budget management, resource allocation, collaboration,communication, fast management, documentation, and administration of a system that is shared to manage largeprojects with the help of Vue and PHP Laravel programming languages."
						/>
						<div className="administrative__problems__content">
							<img src={vector} className="administrative__problems__content__vector" alt="" />
							<div className="administrative__problems__technologies">
								<img src={laravel} className="administrative__problems__technologies__laravel" alt="" />
								<img src={vue} className="administrative__problems__technologies__vue" alt="" />
							</div>
							<div className="administrative__problems__macWrapper">
								<img src={macStudio} className="image" alt="" />
							</div>
						</div>
					</div>
					<div className="administrative__result">
						<h2 className="administrative__result__title">Result</h2>
						<div className="administrative__result__content">
							<img
								src={result1}
								className="administrative__result__content__image administrative__result__content__image-first"
								alt=""
							/>
							<img
								src={result2}
								className="administrative__result__content__image administrative__result__content__image-second"
								alt=""
							/>
							<img
								src={result3}
								className="administrative__result__content__image administrative__result__content__image-third"
								alt=""
							/>
							<img src={result3Mob} className="administrative__result__content__image-mob" alt="" />
							<img
								src={result4}
								className="administrative__result__content__image administrative__result__content__image-fourth"
								alt=""
							/>
							<img
								src={result5}
								className="administrative__result__content__image administrative__result__content__image-fifth"
								alt=""
							/>
						</div>
					</div>
					<div className="administrative__review">
						<img src={reviewElipse} className="administrative__review__elipse" alt="" />
						<CaseReview
							name="Alice Cross"
							position="CEO"
							text="Cooperation with Alfa Solutions can be described as professionalism, responsibility, attention to details, completion of tasks on time and full mutual understanding with the customer. Contact them if you want to get a quality product that meets your requirements."
						/>
					</div>
					<CaseFooter
						image={headAndFooterImage}
						classes="administrative__footer"
						imageClasses="administrative__footer__image"
					/>
				</div>
			</div>
		</>
	);
};
