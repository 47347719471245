import React from 'react';
import { CaseTitle } from '../../components/CaseTitle';
import { CaseArticle } from '../../components/CaseArticle';
import { CaseReview } from '../../components/CaseReview';
import { CaseFooter } from '../../components/CaseFooter';
import headElipse from '../../../../../assets/img/portfolio-case-img/courses/headElipse.png';
import arrow from '../../../../../assets/img/portfolio-case-img/courses/arrow.png';
import iMac from '../../../../../assets/img/portfolio-case-img/courses/iMac.png';
import descriptionImage from '../../../../../assets/img/portfolio-case-img/courses/descriptionImage.png';
import iPad from '../../../../../assets/img/portfolio-case-img/courses/iPad.png';
import problemsElipse from '../../../../../assets/img/portfolio-case-img/courses/problemsElipse.png';
import iPadFeedback from '../../../../../assets/img/portfolio-case-img/courses/iPadFeedback.png';
import iPadCourseFormat from '../../../../../assets/img/portfolio-case-img/courses/iPadCourseFormat.png';
import vector from '../../../../../assets/img/portfolio-case-img/courses/vector.png';
import reviewElipse from '../../../../../assets/img/portfolio-case-img/courses/reviewElipse.png';
import '../../Case.scss';
import './CoursesCase.scss';

export const CoursesCase = () => {
	return (
		<>
			<div className="caseContainer">
				<img src={headElipse} className="headImage courses__headImage" alt="" />
				<CaseTitle title="Personal growth courses" classes="courses__caseTitle" />
				<div className="caseBody courses__caseBody">
					<div className="courses__caseAbout">
						<img src={arrow} className="courses__caseAbout__arrow" alt="" />
						<CaseArticle
							classes="courses__caseAbout__article"
							withBackground
							articleText="Self-growth, sometimes referred to as “personal growth” or “personal development,” is a process of developing new skills, attitudes, actions, or reactions that can have a positive impact on your life and increase your overall well-being.
Self-improvement and personal growth are important and highly valuable, parts of your career and life in general. That is why it is so important to invest in this business area because it is developing so actively and will bring income for the owner."
						/>
						<div className="courses__caseAbout__iMacWrapper">
							<img src={iMac} className="image" alt="" />
						</div>
					</div>
					<div className="courses__description">
						<CaseArticle
							classes="courses__description__article"
							withBackground
							articleTitle="Description"
							articleText="Courses in personal development can include categories such as:
Happiness & Fun, Change & Challenges, Mindfulness & Peace, Healthy Habits, Work Fulfillment
,Gratitude & Positivity, Relationships & Love. What can be better than a rich and valuable source that helps you grow your business, be happier, have a great relationship with your partner, and move on against obstacles and problems? A free source that all you need to get is the internet, a device, a single click, and then welcome to a wide world of valuable and practical information.
"
						/>
						<div className="courses__description__imageWrapper">
							<img src={descriptionImage} className="image" alt="" />
						</div>
					</div>
					<div className="courses__problems">
						<img src={problemsElipse} className="courses__problems__elipse" alt="" />
						<div className="courses__problems__iPadWrapper">
							<img src={iPad} className="image" alt="" />
						</div>
						<CaseArticle
							classes="courses__problems__article"
							titleClasses="courses__problems__article-title"
							withBackground
							articleTitle="Problems and Solution"
							articleText=" “Customer Reviews” section. Things that others say about your products have a large impact on buying decisions. You may be tempted to edit negative reviews or write fake ones, but it's much better to stay honest. Real reviews mean a wise purchasing decision as well as fewer returns and refunds.
Blog. The benefits of business blogging have been well-documented over the years. Businesses that blog drive more traffic to their websites, are more well-known within their industry, and have better long-term marketing and sales results.
Prices. Not having prices on your website makes people feel uncomfortable and intimidated, and they'll find another website where they know what things cost
Contact information (location, telephone, etc.) You can also add a callback modal. It will add to your website more understanding of potential customers while speaking with them by phone.
Social media It can widen your field of business through other platforms"
						/>
					</div>
					<div className="courses__details">
						<img src={vector} className="courses__details__vector" alt="" />
						<CaseArticle
							classes="courses__details__article"
							withBackground
							articleTitle="Details"
							articleText="Personal development blogs or courses can be an endless gate toward inspiration, motivation, and success, particularly if an expert person has made the content and the website. If you want to be a part of a fast growing and high loaded business sphere - you can start your own story right now with our help.
"
						/>
						<div className="courses__details__imageWrapper">
							<img src={iPadFeedback} className="courses__details__iPadFeedback" alt="" />
							<img src={iPadCourseFormat} className="courses__details__iPadCourseFormat" alt="" />
						</div>
					</div>
					<div className="courses__review">
						<img src={reviewElipse} className="courses__review__elipse" alt="" />
						<CaseReview
							name="Chester Smith"
							position="CEO"
							text="“Cooperation with Alpha Solution has brought our software to a whole new level. Now we use convenient and new technologies that allow us to work with convenience. We will definitely cooperate in the future and improve the capabilities of our software together.”"
						/>
					</div>
					<CaseFooter image={iMac} imageClasses="courses__footer__image" classes="courses__footer" />
				</div>
			</div>
		</>
	);
};
