import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './input.scss';

export default function Input({
	type,
	name,
	isDirty,
	onBlur,
	styles,
	placeholder,
	value,
	classes,
	onChange,
	register,
	errors,
}) {
	const addClassActive = isDirty && value.length > 0;

	return (
		<input
			className={classNames(`input input-${styles} ${classes}`, {
				'input-field__active': addClassActive,
				'input-field__invalid': errors[name] !== undefined,
			})}
			type={type}
			onBlur={onBlur}
			placeholder={placeholder}
			onChange={onChange}
			{...register(name)}
		/>
	);
}

Input.propTypes = {
	styles: PropTypes.oneOf(['field', 'message', 'search']),
	type: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.string,
	inputValue: PropTypes.string,
	onBlur: PropTypes.func,
	classes: PropTypes.string,
	name: PropTypes.string,
	isDirty: PropTypes.bool,
	onChange: PropTypes.func,
	register: PropTypes.any,
	errors: PropTypes.object,
};
