import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import closeBtn from '../../../../assets/svg/IconBtn/closeBtn.svg';
import './styles/CloseCase.scss';

export const CloseCase = ({ openCase }) => (
	<ReactSVG className="closeCont__closeBtn" src={closeBtn} onClick={openCase} />
);

CloseCase.propTypes = {
	openCase: PropTypes.func.isRequired,
};
