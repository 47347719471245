import React from 'react';
import PropTypes from 'prop-types';
import './styles/CaseArticle.scss';
import cn from 'classnames';

export const CaseArticle = ({
	articleText = '',
	articleTitle = '',
	classes = '',
	titleClasses = '',
	textClasses = '',
	withBackground = false,
}) => {
	return (
		<div className={cn('caseArticle-wrapper', classes, { 'caseArticle-background': withBackground })}>
			{articleTitle && <h2 className={cn('caseArticle-title', titleClasses)}>{articleTitle}</h2>}
			<p className={`caseArticle-text ${textClasses}`}>{articleText}</p>
		</div>
	);
};
CaseArticle.propTypes = {
	articleText: PropTypes.string,
	articleTitle: PropTypes.string,
	classes: PropTypes.string,
	titleClasses: PropTypes.string,
	textClasses: PropTypes.string,
	withBackground: PropTypes.bool,
};
