import React from 'react';
import PropTypes from 'prop-types';
import './styles/CaseReview.scss';

export const CaseReview = ({ name = '', position = '', text = '' }) => {
	return (
		<div className="caseReview">
			<h2 className="caseReview__title"> Review</h2>
			<div className="caseReview-wrapper">
				<h2 className="caseReview-name">{name}</h2>
				<p className="caseReview-position">{position}</p>
				<p className="caseReview-text">{text}</p>
			</div>
		</div>
	);
};

CaseReview.propTypes = {
	name: PropTypes.string,
	position: PropTypes.string,
	text: PropTypes.string,
};
