import React from 'react';
import logo from '../../../assets/svg/logo.svg';
import '../scss/logo.scss';

export default function Logo() {
	return (
		<div className="header-logo">
			<a href="/" className="header-logo__link">
				<img src={logo} alt="logo" className="header-logo__img" />
			</a>
		</div>
	);
}
