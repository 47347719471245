import React from 'react';
import PropTypes from 'prop-types';
import CardsImg from './components/CardsImg';
import CardsText from './components/CardsText';
import Button from '../../../ui/button/button';
import './scss/Cards.scss';

export const BlogCard = ({ blogData, onClick, tagHandleClick = () => {} }) => {
	const { title, thumbnail, content, categories } = blogData;

	return (
		<div className="blog-wrapper__cards">
			<CardsImg srcImg={thumbnail} altImg="article" onClick={onClick} />
			<CardsText headerTitle={title} articleText={content} onClick={onClick} />

			<div className="blog-wrapper__cards-tags">
				{categories.map(tag => {
					return <Button mainClass="tag" title={tag} key={Math.random()} onClick={() => tagHandleClick(tag)} />;
				})}
			</div>
		</div>
	);
};

BlogCard.propTypes = {
	title: PropTypes.string,
	thumbnail: PropTypes.element,
	content: PropTypes.string,
	blogData: PropTypes.object,
	onClick: PropTypes.func,
	tagHandleClick: PropTypes.func,
};
