import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../ui/button/button';
import Icon from '../../../ui/button/icon/icon';
import CaptionH2 from '../../../ui/caption/CaptionH2';
import BottomWrapper from '../../../ui/BottomWrapper/BottomWrapper';
import './ReadBlog.scss';
import ArrowRight from '../../../assets/svg/IconBtn/arrow-right.svg';
import { ArticlesBlock } from '../../BlogPage/components/ArticlesBlock';
import { fetchPost } from '../../../redux/actions';

export const ReadBlog = ({ titleText }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const post = useSelector(state => state.posts.posts);
	const [openArticle, setOpenArticle] = useState(true);

	useEffect(() => {
		dispatch(fetchPost());
	}, []);

	const clickHandler = id => {
		setOpenArticle(!openArticle);

		if (openArticle) {
			history.push(`/article/${id}`);
		}
	};

	return (
		<>
			<BottomWrapper>
				<div className="container">
					<div className="read-more__wrapper">
						<CaptionH2 styles="first" title={titleText} />
						<ArticlesBlock articles={post} articleAmount={4} onClick={clickHandler} />
						<Button
							mainClass="primary"
							secClass="small"
							thirdClass="arrow"
							title="See more articles"
							classes="articles-btn"
							icon={<Icon imageUrl={ArrowRight} imgId="arrow-right" imgClassName="arrow-right" />}
							onClick={() => history.push('/blog')}
						/>
					</div>
				</div>
			</BottomWrapper>
		</>
	);
};

ReadBlog.propTypes = {
	titleText: PropTypes.string.isRequired,
};
