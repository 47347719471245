import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import Button from '../../../../ui/button/button';

export const Cards = ({ classes, captionTitle, id, handleClickMob, imgSrc, cardText, currentId }) => {
	return (
		<>
			<div className={classNames(`technologies-content__cards-${classes}`, { 'active-cards': currentId === id })}>
				<span className={classNames(`technologies-content__cards-${classes}_title`)}>{captionTitle}</span>
				<Button mainClass="secondary" title="Read more" onClick={() => handleClickMob(id)} classes="technologies-btn" />
				<ReactSVG className={classNames(`technologies-content__cards-${classes}_img`)} src={imgSrc} />
				<span className={classNames(`technologies-content__cards-${classes}_text`)}>
					<div>{cardText}</div>
				</span>
			</div>
		</>
	);
};

Cards.propTypes = {
	captionTitle: PropTypes.string,
	imgSrc: PropTypes.string,
	classes: PropTypes.oneOf(['react', 'vue', 'node', 'php']),
	cardText: PropTypes.string,
	currentId: PropTypes.any,
	id: PropTypes.number,
	handleClickMob: PropTypes.func,
};
