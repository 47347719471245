import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './assets/scss/index.scss';
import Header from 'components/header/header';
import Footer from './components/footer/footer';
import { HomePage } from './pages/HomePage/HomePage';
import { BlogPage } from './pages/BlogPage/BlogPage';
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage';
import { TechnologiesPage } from './pages/TechnologiesPage/TechnologiesPage';
import { PortfolioPage } from './pages/PortfolioPage/PortfolioPage';
import { PackagesOfCooperationPage } from './pages/Packages-Of-CooperationPage/Packages-Of-CooperationPage';
import { ContactUsPage } from './pages/Contact-UsPage/Contact-UsPage';
import { Article } from './pages/BlogPage/Article/Article';
import ScrollUp from './components/ScrollUp/ScrollUp';
import MVPPage from './pages/MVPPage/MVPPage';

export default function App() {
	return (
		<>
			<div className="app">
				<Header />
				<div className="content">
					<Switch>
						<Route exact path="/" component={HomePage} />
						<Route path="/technologies" component={TechnologiesPage} />
						<Route path="/portfolio" component={PortfolioPage} />
						<Route path="/packages-of-cooperation" component={PackagesOfCooperationPage} />
						<Route path="/mvp" component={MVPPage} />
						<Route path="/blog" component={BlogPage} />
						<Route path="/contact-us" component={ContactUsPage} />
						<Route path="/article/:id" component={Article} />
						<Route path="*" component={NotFoundPage} />
					</Switch>
				</div>
				<ScrollUp />

				<Footer />
			</div>
		</>
	);
}
