import React from 'react';
import PropTypes from 'prop-types';
import './BottomWrapper.scss';

export default function BottomWrapper({ children }) {
	return (
		<>
			<section className="bottom-wrapper">{children}</section>
		</>
	);
}

BottomWrapper.propTypes = {
	children: PropTypes.node,
};
