import React from 'react';
import { ReactSVG } from 'react-svg';
import { useHistory } from 'react-router-dom';
import Emoji from './Emoji';
import Button from '../../../ui/button/button';
import './scss/SectionSoftware.scss';
import eyesIc from '../../../assets/img/emoji/pic_eyes.png';
import heroImage from '../../../assets/svg/HomePage/homepage-hero-image.svg';
import tongueIc from '../../../assets/img/emoji/pic_face-with-tongue.png';
import arrowScroll from '../../../assets/svg/IconBtn/arrow-scroll-to-see-more.svg';
import sunglassesIc from '../../../assets/img/emoji/pic_smiling-face-with-sunglasses.png';

export default function SectionTop() {
	const history = useHistory();

	const scrollBtnClick = () => {
		let scroll;

		if (window.innerWidth >= 1366 + 0.2) {
			scroll = window.innerHeight - 128 / 2;
		} else if (window.innerWidth <= 1366 && window.innerWidth >= 768 + 0.2) {
			scroll = 628 - 91 / 2;
		} else if (window.innerWidth <= 768 && window.innerWidth >= 425 + 0.2) {
			scroll = window.innerHeight - 120;
		} else if (window.innerWidth <= 425) {
			scroll = window.innerHeight - 60;
		}

		return window.scrollTo({
			top: scroll,
			behavior: 'smooth',
		});
	};

	return (
		<div className="container">
			<div className="section-software">
				<div className="section-software__container">
					<div className="section-software__container-text">
						<h1>Software Development</h1>
						<span>Shipping your ideas to live</span>
					</div>
					<Button
						mainClass="primary"
						onClick={() => history.push('/contact-us')}
						secClass="big"
						classes="homepage"
						title="Contact us"
					/>
				</div>
				<div className="section-software__image">
					<ReactSVG src={heroImage} />
					<Emoji emojiStyle="first" title="Back-end solutions" image={tongueIc} />
					<Emoji emojiStyle="second" title="Front-end solutions" image={eyesIc} />
					<Emoji emojiStyle="third" title="Design solutions" image={sunglassesIc} />
				</div>
			</div>
			<div className="scroll-container">
				<div className="scroll-content" onClick={scrollBtnClick}>
					<span>Scroll to see more</span>
					<ReactSVG className="scroll-content__arrow" src={arrowScroll} />
				</div>
			</div>
		</div>
	);
}
