import React from 'react';
import { NavLink } from 'react-router-dom';
import '../scss/footerNav.scss';
import PropTypes from 'prop-types';

export default function FooterNav({ links, classes }) {
	return (
		<nav className={`footer-navbar ${classes}`}>
			{links.map(link => {
				const { id, title, route } = link;
				return (
					<NavLink className="footer-navbar__item" to={route} key={id}>
						{title}
					</NavLink>
				);
			})}
		</nav>
	);
}
FooterNav.propTypes = {
	links: PropTypes.array,
	classes: PropTypes.string,
};
