import { put } from 'redux-saga/effects';
import * as ActionCreators from '../actions';
import * as API from '../api';

export function* sendFormSaga(action) {
	try {
		const {
			payload: { form },
		} = action;
		const {
			body: {
				form: { newForm },
			},
		} = yield API.sendForm(form);
		yield put(ActionCreators.sendFormSuccess({ form: newForm }));
	} catch (error) {
		yield put(ActionCreators.sendFormError(error));
	}
}
