import React from 'react';
import { useParams } from 'react-router';
import { ReadBlog } from '../../PortfolioPage/ReadBlog/ReadBlog';
import { articles } from './consts';
import './Article.scss';
import { ContentArticle } from './ContentArticle';

export const Article = () => {
	const { id } = useParams();

	const article = articles.filter(link => Number(id) === link.index);
	const {
		srcImgHeader,
		captionArticle,
		titleText,
		headerTitle,
		cardsEntity,
		resultCaption,
		tagsTitle,
		resultText,
		index,
	} = article[0];

	return (
		<>
			<div className="article-wrapper">
				<ContentArticle
					srcImgHeader={srcImgHeader}
					captionArticle={captionArticle}
					titleText={titleText}
					headerTitle={headerTitle}
					resultCaption={resultCaption}
					tagsTitle={tagsTitle}
					resultText={resultText}
					cardsEntity={cardsEntity}
					id={index}
					key={Math.random()}
				/>
			</div>
			<ReadBlog titleText="Read more articles" />
		</>
	);
};
