import React from 'react';
import PropTypes from 'prop-types';
import './styles/CaseTitle.scss';

export const CaseTitle = ({ title = '', classes = '' }) => {
	return <h2 className={`caseTitle ${classes}`}>{title}</h2>;
};
CaseTitle.propTypes = {
	title: PropTypes.string,
	classes: PropTypes.string,
};
