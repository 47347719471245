import illReact from '../../../../assets/svg/TechnplogiesPage/ill_technology-1.svg';
import illVue from '../../../../assets/svg/TechnplogiesPage/ill_technology-2.svg';
import illNode from '../../../../assets/svg/TechnplogiesPage/ill_technology-3.svg';
import illPhp from '../../../../assets/svg/TechnplogiesPage/ill_technology-4.svg';

export const TechnologiesData = [
	{
		imgSrc: illReact,
		classes: 'react',
		cardText:
			'React.js is a JavaScript framework for crafting complex & advanced user interfaces within single-page' +
			' web apps',
		id: 21,
		captionTitle: 'React',
	},
	{
		imgSrc: illVue,
		classes: 'vue',
		cardText: 'Vue.js is a JavaScript framework for crafting user interfaces and single-page apps',
		id: 22,
		captionTitle: 'Vue.js',
	},
];

export const TechnologiesDataBottom = [
	{
		imgSrc: illNode,
		classes: 'node',
		cardText:
			'Node.js is a JavaScript runtime built on the Chrome V8 JavaScript engine for backend (server-side)	development',
		id: 23,
		captionTitle: 'Node.js',
	},
	{
		imgSrc: illPhp,
		classes: 'php',
		cardText: 'PHP is a general-purpose scripting language suited to dynamic web pages',
		id: 24,
		captionTitle: 'PHP',
	},
];
