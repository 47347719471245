import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from '../../../../ui/button/button';
import './styles/CaseFooter.scss';

export const CaseFooter = ({ image, imageClasses, classes }) => {
	const history = useHistory();
	return (
		<div className={`caseFooter-wrapper ${classes}`}>
			<div className={`caseFooter-image__wrapper ${imageClasses}`}>
				<img className="caseFooter-image" src={image} alt="footerImage" />
			</div>
			<div className="caseFooter-info">
				<h2 className="caseFooter-title">Thanks for watching</h2>
				<p className="caseFooter-text">If you liked it and have some ideas - click the button below</p>
				<Button
					classes="caseFooter-btn"
					mainClass="primary"
					secClass="big"
					title="Contact us"
					btnType="submit"
					onClick={() => history.push('/contact-us')}
				/>
			</div>
		</div>
	);
};

CaseFooter.propTypes = {
	image: PropTypes.string,
	imageClasses: PropTypes.string,
	classes: PropTypes.string,
};
