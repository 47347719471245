import React from 'react';
import PropTypes from 'prop-types';
import './caption-small.scss';

export default function CaptionH3({ title, margin, classes }) {
	// function cleanTitle(checkTitle) {
	// 	checkTitle = checkTitle.replaceAll('amp;', '');
	// 	return checkTitle;
	// }

	return (
		<>
			<h3 className={`caption-small caption-small__${margin} ${classes}`}>{title}</h3>
		</>
	);
}

CaptionH3.propTypes = {
	margin: PropTypes.oneOf(['first', 'second', 'popUpSent']),
	title: PropTypes.string.isRequired,
	classes: PropTypes.string,
};
