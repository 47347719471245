import React, { useEffect, useState } from 'react';
import { Card } from './Card/Card';
import { ReadBlog } from './ReadBlog/ReadBlog';
import { cases } from './consts';
import '../../assets/scss/index.scss';
import './Card/Card.scss';
import CaptionH2 from '../../ui/caption/CaptionH2';
import { PopUpCase } from './Case/PopUpCase';

export const PortfolioPage = () => {
	useEffect(() => {
		document.title = 'Portfolio';
	});

	const [isHidden, setIsHidden] = useState(true);
	const [popupId, setPopupId] = useState(null);
	const openCase = id => {
		setPopupId(id);
		setIsHidden(!isHidden);
		if (isHidden) {
			document.body.classList.add('scroll');
		} else {
			document.body.classList.remove('scroll');
		}
	};

	return (
		<>
			<div className="portContainer">
				<CaptionH2 title="Portfolio" styles="first" />
				<div className="container">
					<div className="portContainer__inner">
						{cases.map(link => {
							const { imgSrc, headerTitle, articleText, altImg, id } = link;
							return (
								<>
									<Card
										imgSrc={imgSrc}
										headerTitle={headerTitle}
										articleText={articleText}
										altImg={altImg}
										openCase={() => openCase(id)}
										key={Math.random()}
									/>
								</>
							);
						})}
						{isHidden ? null : <PopUpCase id={popupId} openCase={openCase} />}
					</div>
				</div>
			</div>
			<ReadBlog titleText="Read our blog" />
		</>
	);
};
