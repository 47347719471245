import React from 'react';
import { CaseTitle } from '../../components/CaseTitle';
import headElipse from '../../../../../assets/img/portfolio-case-img/clothesEcommerce/headElipse.png';
import headElipseMob from '../../../../../assets/img/portfolio-case-img/clothesEcommerce/headElipseMob.png';
import caseAboutImac from '../../../../../assets/img/portfolio-case-img/clothesEcommerce/caseAboutImac.png';
import caseImg from '../../../../../assets/img/portfolio-case-img/clothesEcommerce/caseImg.png';
import descriptionImage from '../../../../../assets/img/portfolio-case-img/clothesEcommerce/descriptionImage.png';
import bomberScreen from '../../../../../assets/img/portfolio-case-img/clothesEcommerce/bomberScreen.png';
import problemslipse from '../../../../../assets/img/portfolio-case-img/clothesEcommerce/problemsElipse.png';
import basketImage from '../../../../../assets/img/portfolio-case-img/clothesEcommerce/basketScreen.png';
import sweatshirtsScreen from '../../../../../assets/img/portfolio-case-img/clothesEcommerce/sweatshirtsScreen.png';
import categoriesScreen from '../../../../../assets/img/portfolio-case-img/clothesEcommerce/categoriesScreen.png';
import reviewElipse from '../../../../../assets/img/portfolio-case-img/clothesEcommerce/reviewElipse.png';
import footerImage from '../../../../../assets/img/portfolio-case-img/clothesEcommerce/footerImage.png';
import '../../Case.scss';
import './ClothesEcommerceCase.scss';
import { CaseArticle } from '../../components/CaseArticle';
import { CaseReview } from '../../components/CaseReview';
import { CaseFooter } from '../../components/CaseFooter';

export const ClothesEcommerceCase = () => {
	return (
		<>
			<div className="caseContainer">
				<CaseTitle title="E-commerce" />
				<img src={headElipse} className="headImage clothesEcommerce__headImage" alt="" />
				<img src={headElipseMob} className="headImage clothesEcommerce__headImage-mob" alt="" />
				<div className="caseBody clothesEcommerce__caseBody">
					<div className="clothesEcommerce__caseAbout">
						<CaseArticle
							withBackground
							classes="clothesEcommerce__caseAbout__article"
							articleText="Starting an online clothing store after the pandemic is one of the wisest decisions you can make. The fashion industry is one of the most appealing and followed in the world, with the global market valued at over 3 trillion dollars.
"
						/>
						<div className="clothesEcommerce__caseAbout__imageWrapper">
							<img
								src={caseImg}
								className="clothesEcommerce__caseAbout__case clothesEcommerce__caseAbout__case_small"
								alt=""
							/>
							<img
								src={caseImg}
								className="clothesEcommerce__caseAbout__case clothesEcommerce__caseAbout__case_middle"
								alt=""
							/>
							<img
								src={caseImg}
								className="clothesEcommerce__caseAbout__case clothesEcommerce__caseAbout__case_big"
								alt=""
							/>
							<img src={caseAboutImac} className="image" alt="" />
						</div>
					</div>
					<div className="clothesEcommerce__description">
						<CaseArticle
							withBackground
							classes="clothesEcommerce__description__article"
							articleTitle="Description"
							articleText="An Eye-Catching Homepage
A homepage is usually the very first thing visitors see, making it one of the most important touchpoints.
Also, to stand out from the competitors and prosper, the best fashion websites have to be a combination of a great UX and UI.Сonvenient navigation
Create an easily-navigable fashion designing website so the visitors spend more time there
-Exclusive Product Pages Including High-Quality Visuals and Videos
Clients expect to see huge and attractive high-quality images and videos. Simple visitors can easily convert into customers, increasing your conversions — and thus your profit."
						/>
						<div className="clothesEcommerce__description__imageWrapper">
							<img src={descriptionImage} className="image" alt="" />
						</div>
					</div>
					<div className="clothesEcommerce__problems">
						<img src={problemslipse} className="clothesEcommerce__problems__elipse" alt="" />
						<div className="clothesEcommerce__problems__imageWrapper">
							<img src={bomberScreen} className="image" alt="" />
						</div>
						<CaseArticle
							withBackground
							classes="clothesEcommerce__problems__article"
							titleClasses="clothesEcommerce__problems__article-title"
							articleTitle="Problem and Solution"
							articleText="Additional details:
”Add to bag” button. It should be visible on the page, enticing a user into hitting it as soon as possible.
Product descriptions. They must depict the product in detail, focusing on the peculiarities and benefits it offers
Social media buttons. To increase brand awareness and additional sales
Wishlist To allow the client to return in your marketplace and buy his favorites
Personal account. For shipping and main shopping processes
Localization If you want to enhance the understanding of your products
Site search.When customers want access to information quickly, a search is the easiest way to do so. "
						/>
					</div>
					<div className="clothesEcommerce__details">
						<div className="clothesEcommerce__details__content">
							<CaseArticle
								withBackground
								classes="clothesEcommerce__details__article"
								articleTitle="Details"
								articleText="If you want to draw attention to your business or increase your selling level you can contact us and use these basics of creating a successful site. We exactly know your ideas and expectations
"
							/>
							<div className="clothesEcommerce__details__basketWrapper">
								<img src={basketImage} className="image" alt="" />
							</div>
						</div>
						<div className="clothesEcommerce__details__images">
							<div className="clothesEcommerce__details__sweatshirtsScreen">
								<img src={sweatshirtsScreen} className="image" alt="" />
							</div>
							<div className="clothesEcommerce__details__categoriesScreen">
								<img src={categoriesScreen} className="image" alt="" />
							</div>
						</div>
					</div>
					<div className="clothesEcommerce__caseReview">
						<img src={reviewElipse} className="clothesEcommerce__caseReview__elipse" alt="" />
						<CaseReview
							name="Samuel Wald"
							position="CAO"
							text="“The Alpha Solution team helped us reach a new level of online sales. They have significantly improved our software and technology, which allows us to quickly and profitably develop our business. Alpha Solution inspires our company to contact them more than once.”"
						/>
					</div>
					<CaseFooter
						image={footerImage}
						classes="clothesECommerce__footer"
						imageClasses="clothesEcommerce__footer__image"
					/>
				</div>
			</div>
		</>
	);
};
