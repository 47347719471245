import React from 'react';
import PropTypes from 'prop-types';
import './caption.scss';

export default function CaptionH2({ title, styles, classes }) {
	return (
		<>
			<h2 className={`caption caption-${styles} ${classes}`}>{title}</h2>
		</>
	);
}

CaptionH2.propTypes = {
	title: PropTypes.string.isRequired,
	styles: PropTypes.oneOf(['first', 'second']),
	classes: PropTypes.string,
};
