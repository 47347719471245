import React from 'react';
import Button from '../../../../ui/button/button';
import Icon from '../../../../ui/button/icon/icon';
import Socials from '../../../../components/footer/items/socials';
import './scss/SocialLinks.scss';
import twitter from '../../../../assets/svg/SocialsIcon/ic_twitter.svg';
import ArrowMedium from '../../../../assets/svg/IconBtn/arrowBlack.svg';
import facebook from '../../../../assets/svg/SocialsIcon/ic_facebook.svg';
import linkedIn from '../../../../assets/svg/SocialsIcon/ic_linkedin.svg';

export default function SocialLinks() {
	return (
		<>
			<div className="article-content__container article-content__container-links">
				<Button
					mainClass="medium"
					icon={<Icon imageUrl={ArrowMedium} imgId="arrowBlack" imgClassName="arrow-black" />}
					title="We are on Medium"
					onClick={() => window.open('https://medium.com/@a-sol')}
				/>
				<div className="article-content__container-socials">
					<span>Share article:</span>
					<Socials
						fill="dark"
						classes="articles"
						social={[
							{
								src: facebook,
								title: 'facebook',
								route: 'https://m.facebook.com/',
								id: 6,
							},
							{
								src: linkedIn,
								title: 'linkedIn',
								route: 'https://www.linkedin.com/company/a-sol/',
								id: 8,
							},
							{
								src: twitter,
								title: 'twitter',
								route: 'https://twitter.com/',
								id: 9,
							},
						]}
					/>
				</div>
			</div>
		</>
	);
}
